import React, { useEffect, useState } from "react";
import { FaRegFilePdf } from 'react-icons/fa';

const RelatedDocuments = (props) => {
  const [programs,setPrograms] = useState([])

  useEffect(()=> {
    setPrograms(props.files)
  },[]);

  const showData = () => {
      return (
        programs.map((program, index) => {
          return (
            <div className="related-document" key={index}>
              <div style={{color:'#0056b3'}}>
                <FaRegFilePdf size={56} style={{color:'#0056b3', paddingRight: 20}} />
                <a href={program.uri} target='_blank' rel='noopener noreferrer'>
                  {program.filename}
                </a>
              </div>
            </div>
            )
        })
    )
  }

  return (
    <div>
      <div className={'related-documents'}>
        <h5 className="related-documents" />
      </div>
      {showData()}
    </div>
  );
}

export default RelatedDocuments;
