import React from "react";
import {Link} from "react-router-dom";
import IconChevron from "../assets/IconChevron";

function ProgramsInPracticeList(props) {
  return (
    <div className={'card-body'}>
      <h3 className={'card-title'}><Link to={props.link}>{props.title}</Link></h3>
      <h4 className={'card-author-type'}>{props.author_type}</h4>
      <div className={'summary mb-2'}>{props.summary}</div>
      <Link className={'btn-link'} to={props.link}>Learn More about This Program in Practice <IconChevron height={"20px"} width={"20px"} className={"icon chevron ml-2"} fill={"#1e558d"} /></Link>

    </div>
  );
}

export default ProgramsInPracticeList;
