import React from "react";
import {Parser, ProcessNodeDefinitions} from "html-to-react";
import ToggleList from "./ToggleList";
const parser = new Parser();
// yeah this is for real
const isValidNode = function () {
  return true;
};
const processDefinition = new ProcessNodeDefinitions(React);

const BodyParser = function(props) {

  const checkForNestedToggles = function(node) {

    if (node.hasOwnProperty("type") && node.type === "text") return;
    if (node?.attribs?.class === 'toggle') {
      throw Error("Nested toggles are not supported");
    }
    node.children.forEach(checkForNestedToggles);
  }

  if (!props.body) return '';

  let processingInstructions = [
    {
      // This is REQUIRED, it tells the parser
      // that we want to insert our React
      // component as a child
      // replaceChildren: true,
      shouldProcessNode: function (node) {
        return node.attribs && node.attribs['class'] === 'toggle';
      },
      processNode: function (node, children, index) {

        node.children.forEach(checkForNestedToggles);
        // Filter out the text nodes
        let tags = children.filter(c => typeof c !== 'string');

        // The first heading is the handle. I had something more clever, but probably not intuitive.
        let heading = children.find(child => child.type && child.type.indexOf('h') === 0)

        // Anything that isn't the handle is the toggled content
        let contents = children.filter(child => child !== heading);

        return <ToggleList heading={heading} key={index}>{contents}</ToggleList>;
      }
    },
    {
      // The reset get's processed normally
      shouldProcessNode: function (node) {
        return true;
      },
      processNode: processDefinition.processDefaultNode,
    },
  ];
  try {
    return parser.parseWithInstructions(props.body, isValidNode, processingInstructions);
  }
  catch(e) {
    console.log(e)
    return '';
  }

}


export default BodyParser;
