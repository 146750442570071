//@ts-ignore @todo fix
import gradstop from 'gradstop';

export default function (hexColor) {

  const gradient = gradstop({
    // 8 stops to black, but we'll use the first six
    stops: 8,
    inputFormat: 'hex',
    colorArray: [hexColor, '#000000']
  });

  return gradient.map(fullColorHex);

  // this is heavy-handed but the library I fount exports rgb, and in the Cube I'm explicitly using hex.
  function rgbToHex(rgb) {
    let hex = Number(rgb).toString(16);
    if (hex.length < 2) {
      hex = "0" + hex;
    }
    return hex;
  }

  function fullColorHex(rgbSting) {
    let [r,g,b] = rgbSting.slice(rgbSting.indexOf("(")+1, rgbSting.indexOf(")")).split(",");
    const red = rgbToHex(r);
    const green = rgbToHex(g);
    const blue = rgbToHex(b);
    return red+green+blue;
  }
}

