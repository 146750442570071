import React, {useState} from "react";
import AnimateHeight from 'react-animate-height';
import IconChevron from "../assets/IconChevron";

const ToggleList = function(props) {

  let [state, toggle] = useState(true);
  let height = state ? 0 : 'auto';
  let rotate = state ? "" : "rotate(90deg)";
  return (
    <div className={"toggler"} style={{overflow: 'hidden', cursor: 'pointer'}} onClick={() => toggle(state => !state)}>
      <style>{`.toggle-header * {display: inline;}`}</style>
      <div className={"toggle-header"}>
        {props.heading}
        <IconChevron
          height={"20px"}
          width={"20px"}
          className={"icon chevron ml-2"}
          fill={"#141826"}
          style={{transition: "transform 0.5s", transform: rotate, marginTop: "-5px"}}
        />
      </div>
      <AnimateHeight
        duration={ 500 }
        height={ height }
      >
        <div>{props.children}</div>
      </AnimateHeight>
  </div>)
}

export default ToggleList;

