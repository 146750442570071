import React from "react";
import BodyParser from "./BodyParser";
import { Link } from "react-router-dom";
import jsonFetch from "../utilities/cubeFetch";
import IconChevron from "../assets/IconChevron";

class SelfAssessmentReportPage extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(props.location.search);
    const params = queryParams.get("stages");
    if(params)
      localStorage.setItem('queryParams', params)
    const queryIDs = localStorage.getItem('queryParams');
    this.state = {
      results: [],
      queryUUIDs: queryIDs,
    };

    this.getFormReport = this.getFormReport.bind(this);
    this.buildReportCards = this.buildReportCards.bind(this);
  }

  getFormReport = async (inputValue) => {
    const json = await jsonFetch(
      `/drupal/jsonapi/self_assessment_report/${inputValue}`
    ).then((res) => {
      return res;
    });
    this.setState({ results: json });
  };

  buildReportCards(data) {
    if (data.length > 0 && typeof data === "object") {
      var linkDest = "";
      var currentPath = "";
      var nodeTitle = "";
      var stageTitle = "";
      var sectionTitle = "";
      const cards = data.map((program, key) => {
        if (program.type === "Stage") {
          // Stages are not rendered (see return value)
          // But they are used to retrieve the Stage and Section Titles for the next cards.
          const route = this.props.activeRoute.searchByUUID(program.uuid);
          stageTitle = program.field_display_title;
          currentPath = route[0].currentPath;
          if (Array.isArray(route)) {
            sectionTitle = (
              <h5
                className={"stage-breadcrumb mb-3 section-title"}
                style={{ color: route[0].color }}
              >
                {route[0]._parent.pageData["title"]}
              </h5>
            );
          }
          return "";
        } else {
          linkDest =
            currentPath +
            program.title.toLowerCase().trim().replace(/\s+|\//g, "-") +
            "/";
          nodeTitle = program.title;
        }
        return (
          <div className={"card self-assessment-card"} key={key}>
            <div class="section-stage-titles">
              <h5 class="stage-title">{stageTitle}</h5>
              {sectionTitle}
            </div>
            <h3 className={"card-title"}>
              <Link to={linkDest}>{nodeTitle}</Link>
            </h3>
            <div className={"summary mb-2"}>{program.summary}</div>
            <Link className={"btn-link"} to={linkDest}>
              See {program.type} Details
              <IconChevron
                height={"15px"}
                width={"15px"}
                className={"icon chevron ml-2"}
                fill={"#1e558d"}
              />
            </Link>
          </div>
        );
      });
      return (
        <div className={"stages-programs-list"}>
          <h5>Programs Offered</h5>
          <div className={"cards"}>{cards}</div>
        </div>
      );
    } else {
      return (
        <div>
          <h3 className={"card-title"}>No Results Found</h3>
        </div>
      );
    }
  }

  componentDidMount() {
    if (this.state.queryUUIDs) {
      this.getFormReport(this.state.queryUUIDs).then((r) => {
        return r;
      });
    }
  }

  render() {
    const cards = this.buildReportCards(this.state.results);
    const overview =
      this.props.activeRoute.pageData.body !== "" ? (
        <div>
          <h3>Overview </h3>
          <BodyParser body={this.props.activeRoute.pageData.body} />
        </div>
      ) : (
        ""
      );
    return (
      <div className={"content-wrap self-assessment-report"}>
        <h2>Self Assessment Report</h2>
        {overview}
        {cards}
        <Link
          className={"btn btn-primary btn-small ml-2 border-0 p-3 rounded"}
          to={"/self-assessment"}
          fill={"#1e558d"}
        >
          Back to Self Assessment Form
        </Link>
      </div>
    );
  }
}

export default SelfAssessmentReportPage;
