import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import {BoxFactory, stageBoxInitiator, sectionBoxInitiator, positionPlacement} from "./interfaces";
import SectionBox from "./SectionBox";
import StageBox from "./StageBox";

export default class StageBoxFactory implements BoxFactory {

  width: number;
  height: number;
  depth: number;
  box: StageBox;
  color: string;
  parentBox: SectionBox;
  placement: positionPlacement;

  constructor(initiator: stageBoxInitiator, index: number, box: SectionBox, placement: positionPlacement, parentInitiator: sectionBoxInitiator) {
      this.width = initiator.dimensions.width;
      this.height = initiator.dimensions.height;
      this.depth = initiator.dimensions.depth;
      this.color = initiator.color;
      this.parentBox = box;
      this.placement = initiator.parentPlacement;
      initiator.clickHandler = parentInitiator.clickHandler

      const childMesh = StageBox.createStageBox(initiator, box.url);
      const material = new BABYLON.StandardMaterial(initiator.name, box.getScene());
      const color3 = BABYLON.Color3.FromHexString(initiator.color);
      material.ambientColor = color3;
      material.diffuseColor = color3;
      childMesh.material = material;
      // childMesh.material.alpha = 0.1
      childMesh.parent = box;
      // child boxes are always on the bottom.
      childMesh.position.y = - initiator.dimensions.height * 0.5;
      //** This spreads the stage blocks out so they are evenly distributed across the depths of the parent box. */
      childMesh.position.z = this.placement.x *((this.depth/2) + (index * this.depth) - (parentInitiator.dimensions.depth/2));
      this.box = childMesh;
      this.box.setInitialPosition(this.box.position);
      this.addFrontPlane(childMesh);
      BABYLON.Tags.AddTagsTo(this.box, "movable");
  }

  createBox() : StageBox {

    return this.box;

  }

  private addFrontPlane(box: BABYLON.Mesh) {

    const plane: BABYLON.Mesh = BABYLON.MeshBuilder.CreatePlane("myPlane", {width: this.depth, height: this.height});

    const advancedTexture = GUI.AdvancedDynamicTexture.CreateForMesh(plane, 200, 200, false );

    const text1 = new GUI.TextBlock();
    text1.text = box.name;
    text1.color = "white";
    text1.fontSize = 24;
    text1.fontWeight="bold"
    text1.textWrapping = true;
    advancedTexture.addControl(text1);
    //@ts-ignore
    advancedTexture.background = this.color;
    plane.parent = box;
    plane.rotation.y = -this.placement.x * Math.PI/2;
    plane.setPositionWithLocalVector(new BABYLON.Vector3(0,0,-(this.width*0.5+0.001)))


  }
}
