import React from "react";
import {Link} from "react-router-dom";
import BodyParser from "./BodyParser";
import IconChevron from "../assets/IconChevron";

// A Section is one of the Quadrants of the ARK. It has a
// corresponding Drupal Content Type called Section. There are
// five stages per section.
const SectionPage = function(props) {

  const title = typeof props.activeRoute.pageData.title !== "undefined" ? <h1 style={{color: props.activeRoute.cubeState.sectionSelected.color}}>{props.activeRoute.pageData.title}</h1> : "";
  const children = props.activeRoute.children ? props.activeRoute.children.map((child, index) => {
    // we know child.color is defined because we're in the SectionPage template and children of Sections are Stages
    return <li style={{backgroundColor: child.color}} key={child.currentPath + index}><Link to={child.currentPath}>{child.pageData.title}<IconChevron height={"25px"} width={"25px"} className={"icon chevron"} fill={"#fff"} /></Link></li>
  }) : "";
  return (
    <div className={"content-wrap section"}>
      {title}
      <BodyParser body={props.activeRoute.pageData.body} />
      <ul className={"btn-navigation"}>
        {children}
      </ul>
    </div>
  )
}

export default SectionPage;
