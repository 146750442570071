import {IRoute, MenuEntity, PageData} from "./interfaces";
import Route from "./Route";
import jsonFetch from "../utilities/cubeFetch";

class MenuRoute extends Route implements IRoute
{
  position: number;

  constructor(props) {
    super(props);
    this.position = props.position;
  }

  static async createRoutes(menuTree: MenuEntity)
  {
    let json = await jsonFetch(`/drupal/jsonapi/node/${menuTree.bundle}/${menuTree.uuid}`)

    let topRoute = new MenuRoute({
      title: menuTree.label,
      body: json.data.attributes.body.processed,
      uuid: menuTree.uuid
    });

    let childPromises = await menuTree.children.map((child, index) => {

      return jsonFetch(`/drupal/jsonapi/node/${child.bundle}/${child.uuid}`)
        .then(json => {

          let childRoute = new MenuRoute({
            title: child.label,
            body: json.data.attributes.body.processed,
            uuid: child.uuid,
            position:  index
          });
          childRoute.parent = topRoute;
          return childRoute;
        })
    });
    await Promise.all(childPromises);
    return topRoute;

  }

  getColor() {
    return null;
  }

  getActiveMeshes() {
    return [];
  }

}

export default MenuRoute;
