import fetch from "unfetch";

export default async function jsonFetch(uri = "", options = {
  method: "GET",
  cache: "default"
}) {
  const response = await fetch(uri, options)
  if (!response.ok) {
    throw new Error(JSON.stringify(response));
  }
  return response.json();
}

// export default jsonFetch;
