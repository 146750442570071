import Route from "./Route"
import {IRoute} from "./interfaces";

class SearchRoute extends Route
{
  searchIndex: {[key: string]: Array<IRoute>};

  constructor(props)
  {
    super(props);
    this.searchIndex = {};
    // @todo once we add Route to tree on instantiation, we can build the search index in this constructor.
  }

  createSearchIndex() : void
  {

    if (!this.parent) throw new Error("Cannot create search index before the SearchRoute is added to tree.");

    let treeTop = this.getTreeTop(this);

    this.buildSearchIndex(treeTop, this.searchIndex);

  }

  /**
   *
   * @param route
   * @param searchIndex this is by reference; we're making it non-pure.
   */
  private buildSearchIndex(route: IRoute, searchIndex: any) : void
  {

    // always add this route to the index
    if (route.uuid in searchIndex) {
      searchIndex[route.uuid].push(route)
    }
    else {
      searchIndex[route.uuid] = [route];
    }

    // If we're at a leaf, stop.
    if (!route.children || route.children.length === 0) return;

    route.children.forEach(child => this.buildSearchIndex(child, searchIndex))

  }

  searchByUUID(uuid: string) : Array<IRoute>
  {
    if (!this.searchIndex[uuid])  {
      // mh, typescript doesn't mind this.
      return null;
    }

    return this.searchIndex[uuid];
  }

  getActiveMeshes() {
    return [];
  }

}

export default SearchRoute;
