import React from "react";
import BodyParser from "./BodyParser";
import ProgramList from "./ProgramList";
import Breadcrumbs from "./Breadcrumbs";

// A Stage is within a Section. There are five stages per section.
const StagePage = function (props) {
  const title =
    typeof props.activeRoute.pageData.title !== "undefined" ? (
      <h1>{props.activeRoute.pageData.title}</h1>
    ) : (
      ""
    );
  const promisingProgramsCard = props.activeRoute.children ? (
    props.activeRoute.children.filter(
      (child) => child.pageData.type === "promising-program"
    ).length > 0 ? (
      props.activeRoute.children
        .filter((child) => child.pageData.type === "promising-program")
        .map((child, index) => {
          return (
            <div className={"card mb-3"} key={index}>
              <ProgramList
                title={child.pageData.title}
                summary={child.pageData.summary}
                link={child.currentPath}
              />
            </div>
          );
        })
    ) : (
      <div className={"no-results"}>
        There have been no promising programs identified at this time.
      </div>
    )
  ) : (
    <div className={"no-results"}>
      There have been no promising programs identified at this time.
    </div>
  );

  const evidenceProgramsCard = props.activeRoute.children ? (
    props.activeRoute.children.filter(
      (child) => child.pageData.type === "evidence-based-program"
    ).length > 0 ? (
      props.activeRoute.children
        .filter((child) => child.pageData.type === "evidence-based-program")
        .map((child, index) => {
          return (
            <div className={"card mb-3"} key={index}>
              <ProgramList
                title={child.pageData.title}
                summary={child.pageData.summary}
                link={child.currentPath}
              />
            </div>
          );
        })
    ) : (
      <div className={"no-results"}>
        There have been no evidence based programs identified at this time.
      </div>
    )
  ) : (
    <div className={"no-results"}>
      There have been no evidence based programs identified at this time.
    </div>
  );

  return (
    <div className={"content-wrap stage"}>
      <Breadcrumbs
        url={props.activeRoute.parent.currentPath}
        color={props.activeRoute.parent.cubeState.sectionSelected.color}
        title={props.activeRoute.parent.pageData.title}
      />
      {title}
      <BodyParser body={props.activeRoute.pageData.body} />
      <div className={"program-teaser"}>
        <h3 className={"program-title mb-4"}>Evidence Based Programs</h3>
        {evidenceProgramsCard}
      </div>
      <div className={"program-teaser"}>
        <h3 className={"program-title mb-4"}>Promising Programs</h3>
        {promisingProgramsCard}
      </div>
    </div>
  );
};

export default StagePage;
