import Route from "./Route";
import {DrupalNode, IRoute, Stage } from "./interfaces"
import jsonFetch from "../utilities/cubeFetch";
import PromisingProgramRoute from "./PromisingProgramRoute";
import EvidenceProgramRoute from "./EvidenceProgramRoute";
import ProgramInPracticeRoute from "./ProgramInPracticeRoute";

// A Stage occurs five times per section of a Cube Face.
// It's drupal equivalent is the Stage Content Type which is a node.
// This Route class does the json fetching and the StagePage class does the rendering

class StageRoute extends Route {

  private readonly _promisingProgramEntities: string;
  private readonly _evidenceBaseProgramEntities: string;
  discriminator: string;

  constructor(node: Stage) {

    const pageData = {
      title: node.attributes.field_display_title,
      body: node.attributes.body ? node.attributes.body.processed : null,
      uuid: node.id,
      summary: node.attributes.body?.summary ? node.attributes.body.summary : null,
      type: ''
    }

    super(pageData);
    this.discriminator = "Stage";
    this._promisingProgramEntities = node.relationships.field_promising_programs.links.related.href;
    this._evidenceBaseProgramEntities =node.relationships.field_evidence_based_programs.links.related.href

  };

  componentDidMount() {
    this.fetchPrograms()

  }

  async fetchPrograms() {
    // If we've already gathered these programs don't re-fetch
    if (this.children) return;

    const promisingPrograms = jsonFetch(this._promisingProgramEntities);
    const evidencePrograms = jsonFetch(this._evidenceBaseProgramEntities);
    const allPrograms = await Promise.all([promisingPrograms, evidencePrograms]);
    this.makePromisingPrograms(allPrograms[0].data);
    this.makeEvidencePrograms(allPrograms[1].data);
  }

  async getRelatedDocument(id: string) {
    try {
      const fileData = await jsonFetch("/drupal/jsonapi/file/file?filter[id][value]=" + id);
          if (fileData && Array.isArray(fileData.data)){
            return {
              filename: fileData.data[0].attributes.filename,
              uri: fileData.data[0].attributes.uri.url
            }
          }
    }  catch(err){
      console.log("fetch failed in stage",err)
    }
  }
  setState(arg0: { filename: any; uri: any; }) {
    throw new Error("Method not implemented.");
  }

  async makeProgramsInPractice(program: PromisingProgramRoute) {
    const pips = await jsonFetch("/drupal/jsonapi/node/program_in_practice?filter[field_ark_program.id][value]=" + program.uuid + "&filter[status][value]=1");

    pips.data.forEach(pip => {
      let relatedDocsArray = [];
      pip.relationships.field_related_documents.data.forEach(async file => {
        if (file.meta.display === true) {
          const pipFile = await this.getRelatedDocument(file.id);
          relatedDocsArray.push(pipFile);
        }
      });
      const programsInPractice = new ProgramInPracticeRoute({
        title: pip.attributes.title,
        body: pip.attributes.field_experience_details ? pip.attributes.field_experience_details.processed : '',
        uuid: pip.id,
        summary: pip.attributes.field_experience_details.summary?  pip.attributes.field_experience_details.summary : '',
        type: 'program-in-practice',
        author_type: pip.attributes.field_author_type,
        fetched_related_documents: relatedDocsArray,
      });
      programsInPractice.parent = program;
    });
  }

  private makePromisingPrograms(programs: Array<DrupalNode>) {
    programs.forEach(program => {
      const promisingProgram = new PromisingProgramRoute({
        title: program.attributes.title,
        body: program.attributes.body ? program.attributes.body.processed : '',
        uuid: program.id,
        summary: program.attributes.body.summary ? program.attributes.body.summary : '',
        type: 'promising-program'
      });
      promisingProgram.parent = this;

      this.makeProgramsInPractice(promisingProgram);

    });
  }

  private makeEvidencePrograms(programs: Array<DrupalNode>) {
    programs.forEach(program => {

      const evidenceProgram = new EvidenceProgramRoute({
        title: program.attributes.title,
        body: program.attributes.body ? program.attributes.body.processed : '',
        uuid: program.id,
        summary: program.attributes.body.summary ? program.attributes.body.summary : '',
        type: 'evidence-based-program'
      });

      evidenceProgram.parent = this;
      this.makeProgramsInPractice(evidenceProgram);
    });
  }
  // Override the base Route recursive so we can conditionally fetch the Program data when needed.
  recursiveGetCurrentRoute(pathArray: Array<string>, currentRoute: IRoute): Promise<IRoute> {
    pathArray.shift();

    if (pathArray.length === 0) return new Promise((resolve, reject) => { return resolve(currentRoute) });

    if (!currentRoute.children) {
      return this.fetchPrograms()
        .then(() => {
          const nextChild = currentRoute.children.find(child => child.pathSegment === pathArray[0]);
          return nextChild;
        }).catch(err => { throw new Error(err) });
    }
    else {
      const nextChild = currentRoute.children.find(child => child.pathSegment === pathArray[0]);

      if (!nextChild) { throw new Error("Bad Route"); }

      return nextChild.recursiveGetCurrentRoute(pathArray, nextChild);
    }
  }

  getParentColor() {
    return this.parent.cubeState.sectionSelected.color;
  }

  getColor() {
    return this.color;
  }

  getActiveMeshes() {
    return [this.cubeState.selectedStage, this.parent.cubeState.selectedSection];
  }

}

export default StageRoute;
function sendValues(data: any) {
  throw new Error("Function not implemented.");
}

