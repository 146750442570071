import React from "react";
import {Link} from "react-router-dom";
import IconChevron from "../assets/IconChevron";

function ProgramList(props) {
  return (
    <div className={'card-body'}>
      <h3 className={'card-title'}><Link to={props.link}>{props.title}</Link></h3>
      {/*
      I'm leaving this here because they've changed their minds twice on whether they want summaries displayed or not.
      <div className={'summary mb-2'}>{props.summary}</div>
      */}
      <Link className={'btn-link'} to={props.link}>See Program Details<IconChevron height={"20px"} width={"20px"} className={"icon chevron ml-2"} fill={"#1e558d"} /></Link>
    </div>
  );
}

export default ProgramList;
