
const arrayFlatten = function(multiLevelArray: Array<any>) : Array<any>
{
    const flat = [];

    multiLevelArray.forEach(item => {
      if (Array.isArray(item)) {
        flat.push(...arrayFlatten(item));
      } else {
        flat.push(item);
      }
    });

    return flat;

}

export default arrayFlatten;
