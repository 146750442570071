import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import {BoxFactory, stageBoxInitiator, sectionBoxInitiator, positionPlacement} from "./interfaces";
import SectionBox from "./SectionBox";
import StageBox from "./StageBox";
import StageBoxFactory from "./StageBoxFactory";
import {IRoute} from "../routes/interfaces";
import gradientGenerator from "../utilities/gradientGenerator";

export default class SectionBoxFactory implements BoxFactory {

    width: number;
    height: number;
    depth: number;
    box: SectionBox;
    color: string;
    placement: positionPlacement;

    constructor(section: IRoute, scene: BABYLON.Scene) {

        const initiator = section.cubeState.sectionSelected;

        let box = SectionBox.createSectionBox(initiator);
        //@ts-ignore
        const material = new BABYLON.StandardMaterial(initiator.name+"_material", scene);
        material.alpha = 0;
        box.material = material;
        const vectorsWorld = box.getBoundingInfo().boundingBox.vectorsWorld;
        // get the actual width by subtracting vectors
        this.width = Math.abs(vectorsWorld[1].x - vectorsWorld[0].x);
        this.height = Math.abs(vectorsWorld[1].y - vectorsWorld[0].y);
        this.depth = Math.abs(vectorsWorld[1].z - vectorsWorld[0].z);
        //@ts-ignore more overloading
        this.color = initiator.color;
        this.box = box;
        this.placement = initiator.position;
        this.setPosition();
        this.addChildren(section, scene);
        this.addPseudoBox();
        this.addFrontPlane(this.box);
        this.addSidePlane(this.box);
        BABYLON.Tags.AddTagsTo(this.box, "movable")

    }

    createBox() {

        return this.box;

    }

    private setPosition() {
        this.box.position.x = this.width * 0.5 * this.placement.x;
        this.box.position.y = this.height * 0.5 * this.placement.y;
        this.box.setInitialPosition(this.box.position);
    }

    private addChildren(section: IRoute, scene: BABYLON.Scene) {

        if (!section.children || section.children.length === 0) return;
        const width: number = this.width;
        const height: number = this.height * 0.5;
        const depth: number = this.depth / section.children.length;
        // @todo this duplicates logic in the RouteTreeBuilder, and indicates the link between stage Routes and Stage Boxes is not tight enough.
        const gradient = gradientGenerator(section.cubeState.sectionSelected.color);
        section.children.forEach((route, index) => {

            const child = {
              attributes: {
                title: route.pageData.title,
                body: ""
              },
              scene: scene,
              dimensions: {width, height, depth},

              clickHandler: ()=>{},
              parentPlacement: this.placement,
              name: route.pageData.title,
              color: "#" + gradient[index]
            }

            const stageFactory = new StageBoxFactory(child, index, this.box, this.placement, section.cubeState.sectionSelected);
            route.cubeState.selectedStage = stageFactory.createBox();
            // route.cubeState.stageSelected.color = "#" + gradient[index]
          });

    }

    private addPseudoBox() {
        const name = "pseudo_" + this.box.name;

        const pseudoBox = BABYLON.MeshBuilder.CreateBox(name, {
            width: this.width,
            height:this.height * 0.5,
            depth: this.depth
        });
        const material = new BABYLON.StandardMaterial(name, this.box.getScene());
        const color3 = BABYLON.Color3.FromHexString(this.color);
        material.ambientColor = color3;
        material.diffuseColor = color3;
        pseudoBox.material = material;

        pseudoBox.parent = this.box;
        pseudoBox.position.y = this.height * 0.25;
    }

    private addFrontPlane(box: BABYLON.Mesh) {

        const plane: BABYLON.Mesh = BABYLON.MeshBuilder.CreatePlane("frontPlane", {width: this.width, height: this.height});

        const advancedTexture = GUI.AdvancedDynamicTexture.CreateForMesh(plane, 200, 200, false );

        const text1 = new GUI.TextBlock();
        text1.text = this.box.name;
        text1.color = "white";
        text1.fontSize = 18;
        text1.fontWeight = "bold"
        text1.textWrapping = true;
        text1.width = 0.65
        advancedTexture.addControl(text1);
        //@ts-ignore
        advancedTexture.background = this.color;
        plane.parent = box;
        plane.position.z = -this.depth * 0.5 - 0.01;
    }
  private addSidePlane(box: BABYLON.Mesh) {

    const plane: BABYLON.Mesh = BABYLON.MeshBuilder.CreatePlane("sidePlane", {width: this.depth, height: this.height/2});

    const advancedTexture = GUI.AdvancedDynamicTexture.CreateForMesh(plane, 600, 125, false );

    const text1 = new GUI.TextBlock();
    text1.text = this.box.name + "\n\n" +"Stages";
    text1.color = "white";
    text1.fontSize = 24;
    text1.fontWeight = "bold"
    text1.textWrapping = true;
    text1.width = 0.55
    text1.lineSpacing = 2.0;

    // const lines = new GUI.Image("lines", "decorative-lines.svg");
    // advancedTexture.addControl(lines);
    advancedTexture.addControl(text1);
    //@ts-ignore
    advancedTexture.background = this.color;
    // advancedTexture = new BABYLON.Texture("PATH TO IMAGE", box.getScene());
    plane.parent = box;
    const x = this.width*0.5*box.position.x + 0.001*box.position.x;
    const y = this.height*0.25;
    const z = 0;
    const newPosition = new BABYLON.Vector3(x,y,z);

    plane.setPositionWithLocalVector(newPosition)
    plane.rotate(BABYLON.Axis.Y,-Math.PI*0.5*box.position.x, BABYLON.Space.LOCAL);
  }
}
