import React from "react";
import {Link} from "react-router-dom";
import BodyParser from "./BodyParser";
import Breadcrumbs from "./Breadcrumbs";
import ProgramsInPracticeList from "./ProgramsInPracticeList";
import IconChevron from "../assets/IconChevron";

const DynamicDescription = function(props) {

  const defaultNoPipText = 'There is no Program-in-Practice content for this program. To submit program-in-practice information for consideration, please click the button below and complete the short form.'
  if (props.havePips === true) {
    return <div className={"container-test"}><div className={"description"}>Click the content cards below for user-generated information about how this program is used in the field or experienced by stakeholders. Please note this content does not reflect the views or opinions of NADCP.</div>
        <Link className={"btn btn-controls blue hide-submit-for-print"} to={{
            pathname: "/program-in-practice-submission",
            state: {spinCube: true}
          }}>Submit Your Program in Practice <IconChevron height={"20px"} width={"20px"} className={"icon chevron mr-2 right"} fill={"#fff"} /></Link>
      {props.cards}</div>;
  }
  return <div className={'container-test'}><div className={'no-results'}>{defaultNoPipText}</div><Link className={"btn btn-controls blue hide-submit-for-print"} to={{
            pathname: "/program-in-practice-submission",
            state: {spinCube: true}
  }}>Submit Your Program in Practice <IconChevron height={"20px"} width={"20px"} className={"icon chevron mr-2 right"} fill={"#fff"} /></Link></div>;

}

const PromisingProgram = function(props) {
  const havePips = props.activeRoute.children
    ? (props.activeRoute.children.filter(child => child.pageData.type === 'program-in-practice').length > 0):false;
  const programsInPracticeCard = props.activeRoute.children
    ? (props.activeRoute.children.filter(child => child.pageData.type === 'program-in-practice').length > 0
      ? props.activeRoute.children.filter(child => child.pageData.type === 'program-in-practice').map((child, index) => {
        return <div className={'card mb-3'} key={index}><ProgramsInPracticeList title={child.pageData.title} summary={child.pageData.summary} link={child.currentPath} author_type={child.pageData.author_type}/></div>;})
      :'')
    : '' ;
  const title = typeof props.activeRoute.pageData.title !== "undefined" ? <h1>{props.activeRoute.pageData.title}</h1> : "";
  const type = <span className={"label-type mb-4"}>{props.activeRoute.pageData.type.replace(/-/g, " ")}</span>;
  const printType = <h2 className={"print-sub-header"} style={{color: props.activeRoute.parent.color}}>{props.activeRoute.pageData.type.replace(/-/g, " ")}</h2>;
  const pipDescription = 'Learning about how a program is used in practice by practitioners, or perceived by participants and other stakeholders, can provide additional dimensions of knowledge to complement the information above.'
  return (
    <div className={"content-wrap program"}>
    <div className={"d-flex"}>
      <Breadcrumbs url={props.activeRoute.parent.parent.currentPath} color={props.activeRoute.parent.color} title={props.activeRoute.parent.parent.pageData.title}/>
      <span className={"breadcrumb-separator"}></span>
      <Breadcrumbs url={props.activeRoute.parent.currentPath} color={props.activeRoute.parent.color} title={props.activeRoute.parent.pageData.title}/>
    </div>
      <div className={"print-titles"}>
        {title}
        {printType}
      </div>
      <div className={"display-titles"}>
        {type}
        {title}
      </div>
      <BodyParser body={props.activeRoute.pageData.body} />
      <div className={"program-in-practice-wrap"}>
        <h3 className={"program-title mb-4"}>Programs in Practice</h3>
        <div className={"description"}>{pipDescription}</div>
        <DynamicDescription havePips={havePips} cards={programsInPracticeCard} />
      </div>
    </div>
  )
}

export default PromisingProgram;
