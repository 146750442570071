import Route from "./Route";

class EvidenceProgramRoute extends Route {

  getActiveMeshes() {
    return this.parent.getActiveMeshes();
  }

}

export default EvidenceProgramRoute;
