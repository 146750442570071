import {sectionBoxInitiator} from "./interfaces";
import * as BABYLON from 'babylonjs';

export default class SectionBox extends BABYLON.Mesh {
    //@todo it would be nice to have this private, but then we can't enumerate it into the box
    private selected: boolean = false;
    private clickHandler: Function;
    url: string;
    private pageBody: string;
    private inactivePosition: BABYLON.Vector3;
    private activePosition: BABYLON.Vector3;

    static createSectionBox(initiator: sectionBoxInitiator) {
        let box = new SectionBox(initiator);
        var vertexData = BABYLON.VertexData.CreateBox(initiator.dimensions);

        vertexData.applyToMesh(box);

        return box;
    }

    constructor(initiator: sectionBoxInitiator) {
        super(initiator.name, initiator.scene);
        this.clickHandler = initiator.clickHandler;
        this.pageBody = initiator.pageBody;
        this.url = initiator.url;
    }

    selectBox() {
        // this.clickHandler(this)
        const active = this.position === this.activePosition;
        if (!active) {
          this.clickHandler(this);
        }
        else {
          // Here is an example where the Routing object should be more tightly coupled to the Mesh objects
          const parentPathString = this.url.slice(0,this.url.lastIndexOf("/"))
          this.clickHandler(parentPathString);
        }
    }

    setInitialPosition(position: BABYLON.Vector3) {
      this.inactivePosition = new BABYLON.Vector3(position.x, position.y, position.z);
      this.activePosition = new BABYLON.Vector3(position.x*1.5, position.y, position.z);
    }

    reset() {
      this.position = this.inactivePosition.clone();
      this.selected = false;
    }

    setSelected() {
      this.position.x = this.position.x*1.5;
    }

    setActive() {
      this.position = this.activePosition;
    }

    setInactive() {
      this.position = this.inactivePosition;
    }

}

// This is copied strait from the `CreatBox` function. This is not great, but I haven't figured out how to copy only the Box attributes desired.

BABYLON.VertexData.CreateBox = function(options: { size?: number, width?: number, height?: number, depth?: number, faceUV?: BABYLON.Vector4[], faceColors?: BABYLON.Color4[], sideOrientation?: number, frontUVs?: BABYLON.Vector4, backUVs?: BABYLON.Vector4, wrap?: boolean, topBaseAt?: number, bottomBaseAt?: number }): BABYLON.VertexData {
    var nbFaces = 6;
    var indices = [0, 1, 2, 0, 2, 3, 4, 5, 6, 4, 6, 7, 8, 9, 10, 8, 10, 11, 12, 13, 14, 12, 14, 15, 16, 17, 18, 16, 18, 19, 20, 21, 22, 20, 22, 23];
    var normals = [0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0];
    var uvs = [];
    var positions = [];
    var width = options.width || options.size || 1;
    var height = options.height || options.size || 1;
    var depth = options.depth || options.size || 1;
    var wrap = options.wrap || false;
    var topBaseAt = (options.topBaseAt === void 0) ? 1 : options.topBaseAt;
    var bottomBaseAt = (options.bottomBaseAt === void 0) ? 0 : options.bottomBaseAt;
    topBaseAt = (topBaseAt + 4) % 4; // places values as 0 to 3
    bottomBaseAt = (bottomBaseAt + 4) % 4; // places values as 0 to 3
    var topOrder = [2, 0, 3, 1];
    var bottomOrder = [2, 0, 1, 3];
    var topIndex = topOrder[topBaseAt];
    var bottomIndex = bottomOrder[bottomBaseAt];
    var basePositions = [1, -1, 1, -1, -1, 1, -1, 1, 1, 1, 1, 1, 1, 1, -1, -1, 1, -1, -1, -1, -1, 1, -1, -1, 1, 1, -1, 1, -1, -1, 1, -1, 1, 1, 1, 1, -1, 1, 1, -1, -1, 1, -1, -1, -1, -1, 1, -1, -1, 1, 1, -1, 1, -1, 1, 1, -1, 1, 1, 1, 1, -1, 1, 1, -1, -1, -1, -1, -1, -1, -1, 1];
    if (wrap) {
        indices = [2, 3, 0, 2, 0, 1, 4, 5, 6, 4, 6, 7, 9, 10, 11, 9, 11, 8, 12, 14, 15, 12, 13, 14];
        basePositions = [-1, 1, 1, 1, 1, 1, 1, -1, 1, -1, -1, 1, 1, 1, -1, -1, 1, -1, -1, -1, -1, 1, -1, -1, 1, 1, 1, 1, 1, -1, 1, -1, -1, 1, -1, 1, -1, 1, -1, -1, 1, 1, -1, -1, 1, -1, -1, -1];
        var topFaceBase: any = [[1, 1, 1], [-1, 1, 1], [-1, 1, -1], [1, 1, -1]];
        var bottomFaceBase: any = [[-1, -1, 1], [1, -1, 1], [1, -1, -1], [-1, -1, -1]];
        var topFaceOrder: any = [17, 18, 19, 16];
        var bottomFaceOrder: any = [22, 23, 20, 21];
        while (topIndex > 0) {
            topFaceBase.unshift(topFaceBase.pop());
            topFaceOrder.unshift(topFaceOrder.pop());
            topIndex--;
        }
        while (bottomIndex > 0) {
            bottomFaceBase.unshift(bottomFaceBase.pop());
            bottomFaceOrder.unshift(bottomFaceOrder.pop());
            bottomIndex--;
        }
        topFaceBase = topFaceBase.flat();
        bottomFaceBase = bottomFaceBase.flat();
        basePositions = basePositions.concat(topFaceBase).concat(bottomFaceBase);
        indices.push(topFaceOrder[0], topFaceOrder[2], topFaceOrder[3], topFaceOrder[0], topFaceOrder[1], topFaceOrder[2]);
        indices.push(bottomFaceOrder[0], bottomFaceOrder[2], bottomFaceOrder[3], bottomFaceOrder[0], bottomFaceOrder[1], bottomFaceOrder[2]);
    }
    var scaleArray = [width / 2, height / 2, depth / 2];
    positions = basePositions.reduce(
        (accumulator: Array<number>, currentValue, currentIndex) => accumulator.concat(currentValue * scaleArray[currentIndex % 3]),
        []
    );

    var sideOrientation = (options.sideOrientation === 0) ? 0 : options.sideOrientation || BABYLON.VertexData.DEFAULTSIDE;

    var faceUV: BABYLON.Vector4[] = options.faceUV || new Array<BABYLON.Vector4>(6);
    var faceColors = options.faceColors;
    var colors = [];

    // default face colors and UV if undefined
    for (var f = 0; f < 6; f++) {
        if (faceUV[f] === undefined) {
            faceUV[f] = new BABYLON.Vector4(0, 0, 1, 1);
        }
        if (faceColors && faceColors[f] === undefined) {
            faceColors[f] = new BABYLON.Color4(1, 1, 1, 1);
        }
    }

    // Create each face in turn.
    for (var index = 0; index < nbFaces; index++) {
        uvs.push(faceUV[index].z, faceUV[index].w);
        uvs.push(faceUV[index].x, faceUV[index].w);
        uvs.push(faceUV[index].x, faceUV[index].y);
        uvs.push(faceUV[index].z, faceUV[index].y);
        if (faceColors) {
            for (var c = 0; c < 4; c++) {
                colors.push(faceColors[index].r, faceColors[index].g, faceColors[index].b, faceColors[index].a);
            }
        }
    }

    // sides
    BABYLON.VertexData._ComputeSides(sideOrientation, positions, indices, normals, uvs, options.frontUVs, options.backUVs);

    // Result
    var vertexData = new BABYLON.VertexData();

    vertexData.indices = indices;
    vertexData.positions = positions;
    vertexData.normals = normals;
    vertexData.uvs = uvs;

    if (faceColors) {
        var totalColors = (sideOrientation === BABYLON.VertexData.DOUBLESIDE) ? colors.concat(colors) : colors;
        vertexData.colors = totalColors;
    }

    return vertexData;
};
