import Route from "./Route";
import {BasicPage} from "./interfaces"

class FrontPageRoute extends Route {

  constructor(node: BasicPage) {

    const pageData = {
      title: node.attributes.title,
      body: node.attributes.body.processed,
      relatedEntities: node.relationships.field_side_page.links.related.href,
      uuid: node.id
    }

    super(pageData);

    this.side = -Math.PI/2;
  }

  get pathSegment()
  {
    return '';
  }

  getActiveMeshes() {
    return [];
  }

}

export default FrontPageRoute;
