import Route from "./Route";

class ProgramInPracticeRoute extends Route {

  getActiveMeshes() {
    return this.parent.getActiveMeshes();
  }
}

export default ProgramInPracticeRoute;
