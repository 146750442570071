import { point3D, plane, cube } from "./interfaces";
import distanceSquared from "./distanceSquared";

const closestPlane = function(p: point3D, c: cube) {
    const origin : plane = {center: {x:0, y:0, z:0}, name: "origin"};

    const closestPlane = Object.keys(c).reduce((acc, currPlane) => {
        
        return distanceSquared(c[currPlane].center, p) < distanceSquared(acc.center, p) ? c[currPlane] : acc;
    }, origin);

    // this is a terrible way to check if we didn't get any closet planes.
    // maybe a deep equals. I haven't been able to get a null || operator to work with typescript
    if (closestPlane.name === "origin") {
        throw Error("The point should be outside the comparison cube.")
    }
    return closestPlane;
}

export default closestPlane;