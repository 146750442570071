import React, { useEffect, useState } from "react";
import { Router, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

import * as BABYLON from "babylonjs";
import RouteTreeBuilder from "./routes/RouteBuilder";
import Page from "./components/Page";
import SectionRoute from "./routes/SectionRoute";
import StageRoute from "./routes/StageRoute";
import PromisingProgramRoute from "./routes/PromisingProgramRoute";
import EvidenceProgramRoute from "./routes/EvidenceProgramRoute";
import DocumentTitle from "react-document-title";
import ProgramInPracticeRoute from "./routes/ProgramInPracticeRoute";
import ScrollToTop from "./components/ScrollToTop";

class AppContainer extends React.Component {
  constructor(props) {
    super(props);

    // These are still being used as placeholders during loading @todo remove
    this.facePages = {
      front: {
        name: "front",
        url: "/",
        pageData: {
          body: "<p class='subtitle'>A blueprint for an evidence-based justice system</p><p>The best outcomes for those involved in the criminal justice system occur when court personnel can assess...</p><a href='/low-risk/high-need'>Lear more about the ARK >></a>",
          title: "Welcome to the Ark",
        },
      },
      left: {
        name: "left",
        url: "/high-risk",
      },
      right: {
        name: "right",
        url: "/low-risk",
      },
    };
    this.state = {
      sections: [],
      page: this.facePages.front.pageData,
      cubeState: null,
      title: "All Rise ARK Projects",
      searchCache: {},
    };
    this.setPageFromFace = this.setPageFromFace.bind(this);
    this.setPage = this.setPage.bind(this);
    this.history = createBrowserHistory(props);
    this.setLocation = this.setLocation.bind(this);
    this.registerScene = this.registerScene.bind(this);
    this.setSearchCache = this.setSearchCache.bind(this);

    // Listen for changes to the current location from Links. Everything flows through here atm
    this.unlisten = this.history.listen(async (location, action) => {
      this.routes.treeTop
        .getCurrentRoute(location.pathname)
        .then((activeRoute) => {
          let autoTurn =
            typeof location.state !== "undefined" && location.state.spinCube;
          this.changeCube(activeRoute, autoTurn);

          if (typeof activeRoute.fetchPrograms === "function") {
            this.setState({ activeRoute: null });
            activeRoute.fetchPrograms().then((res) =>
              this.setState({
                activeRoute,
                title: activeRoute.pageData.title,
              })
            );
          }
          // if we are coming from the menu, show the spinner for 1 sec to draw the user's eye to the content area.
          else if (location.state && location.state.fromMenu) {
            this.setState({ activeRoute: null });
            setTimeout(() => {
              this.setState({ activeRoute, title: activeRoute.pageData.title });
            }, 1000);
          } else {
            this.setState({ activeRoute, title: activeRoute.pageData.title });
          }
        })
        .catch(console.log);
    });
  }

  prepareAndTriggerAction(pageState, webGLSection = null) {
    const actionManagers = this.changeCube(pageState);
    actionManagers.forEach((actionManager) =>
      actionManager.processTrigger(BABYLON.ActionManager.NothingTrigger)
    );
  }

  registerScene(scene) {
    this.changeCube = function(activeRoute, autoTurn = false) {
      const movableMeshes = scene.getMeshesByTags("movable");

      // clicking Sections and Stages affects the cube state
      if (
        activeRoute instanceof SectionRoute ||
        activeRoute instanceof StageRoute ||
        activeRoute instanceof PromisingProgramRoute ||
        activeRoute instanceof EvidenceProgramRoute ||
        activeRoute instanceof ProgramInPracticeRoute
      ) {
        // @todo make the selectedMesh a union type between Sections and Stages they are very polymorphic as is
        const activeMeshes = activeRoute.getActiveMeshes();

        movableMeshes.forEach((mesh) => {
          mesh.setInactive();
        });

        activeMeshes.forEach((mesh) => {
          mesh.setActive();
        });
      }
      // Otherwise on turn we reset the cubes.
      else {
        movableMeshes.forEach((mesh) => {
          mesh.setInactive();
        });
      }

      const newAlpha = activeRoute.getCubeSide();
      if (newAlpha === null) return;

      const camera = scene.cameras[0];

      // If we're close enough, let's not get fiddly
      if (Math.abs(camera.alpha - newAlpha) < 0.5) return;

      clearTimeout(this.state.timer);

      let timer = setTimeout(() => {
        let keysA = [
          { frame: 0, value: camera.alpha },
          { frame: 30, value: newAlpha },
        ];
        let keysB = [
          { frame: 0, value: camera.beta },
          { frame: 30, value: 1.5 },
        ];
        camera.animations[0].setKeys(keysA);
        camera.animations[1].setKeys(keysB);
        scene.beginAnimation(camera, 0, 30, false);
      }, 100);

      this.setState({ timer });
    };
  }

  componentDidMount() {
    const routeBuilder = new RouteTreeBuilder();
    routeBuilder
      .build()
      .then(async (routeBuilder) => {
        const sections = await routeBuilder.getSections(routeBuilder.treeTop);
        const sectionArray = sections.map((section) => {
          // @todo this can definitely go elsewwhere right now it's passed down to stages as well
          section.cubeState.sectionSelected.url = section.currentPath;
          // This is the primary
          section.cubeState.sectionSelected.clickHandler = (box) => {
            // We don't have box objects for the sides, so when we deselect a Section Box we can only pass in a url string.

            if (typeof box === "string") {
              this.history.push(box);
            } else {
              this.history.push(box.url);
            }
          };

          return section;
        });
        this.setState({ sections: sectionArray });
        this.history.push(this.history.location.pathname, { spinCube: true });
      })
      .catch((err) => {
        console.log("error", err);
      });

    this.routes = routeBuilder;
  }
  componentWillUnmount() {
    this.unlisten();
  }

  // Sets the page state if it receives a valid face name, and checks that it has changed.
  setPageFromFace(face) {
    let path = this.facePages[face.name] ? this.facePages[face.name].url : "/";
    let currentPath = this.history.location.pathname;
    if (path !== currentPath) {
      const newPage = this.routes.extractByPath(path);
      this.setLocation(path);
    }
  }

  setLocation(path) {
    this.history.replace(path);
  }

  setPage(page, path) {
    this.setState({ page });
  }

  setSearchCache(searchTerm, results) {
    this.setState({
      searchCache: { [searchTerm]: results, ...this.state.searchCache },
    });
  }

  render() {
    const { location, history } = this.props
    return (
      <DocumentTitle title={this.state.title}>
        <Router history={this.history}>
          <ScrollToTop />
          <Page
            sections={this.state.sections}
            setPageFromFace={this.setPageFromFace}
            cubeState={{ position: { x: 0, y: 0, z: -9 } }}
            registerScene={this.registerScene}
            activeRoute={this.state.activeRoute}
            history={history}
            setSearchCache={this.setSearchCache}
            searchCache={this.state.searchCache}
            location={location}
          />
        </Router>
      </DocumentTitle>
    );
  }
}

export default withRouter(AppContainer);
