import React from "react";
import BodyParser from "./BodyParser";
import Breadcrumbs from "./Breadcrumbs";
import SectionTeaser from "./SectionTeaser";

const DefaultPage = function(props) {

  const activeRoute = props.activeRoute;

  const title = typeof activeRoute.pageData.title !== "undefined" ? <h1>{activeRoute.pageData.title}</h1> : "";

  const parent = activeRoute.parent;

  const children = activeRoute.children ? activeRoute.children.map((child, index) => {

    let childColor = child?.cubeState?.sectionSelected?.color || "#515154";

    return <div className={"section-wrap"} key={child.currentPath + index}>
      <SectionTeaser title={child.pageData.title} link={child.currentPath} color={childColor} summary={child.pageData.summary}/>
    </div>
  }) : "";

  const pageColor = parent?.cubeState?.sectionSelected ? parent.cubeState.sectionSelected.color : "#515154";

  return (
    <div className={"content-wrap default"}>
      <Breadcrumbs url={parent.currentPath} color={pageColor} title={parent.pageData.title}/>
      {title}
      <BodyParser body={activeRoute.pageData.body}/>
      {children}
    </div>
  )
}

export default DefaultPage;
