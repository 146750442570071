import { IRoute, RouteTree } from "./interfaces";

const selectRouteFromTree = function(path: Array<string>, routeTree: RouteTree) : IRoute
{

  if(path.length === 1) return routeTree[path[0]];

  const currentRoute = routeTree[path[0]];

  return selectRouteFromTree(path.slice(1,path.length), currentRoute.subRoutes)
}

export default selectRouteFromTree;
