import React from "react";
import MenuItem from "./MenuItem";

function Menu(props) {
  return (
    <ul className={"pl-0"}>
      {props.menu.map((item, index) => {
        let open = item.uuid + "-" +  index === props.openMenu;
        return <MenuItem
          key={item.uuid + "-" +  index}
          toggleKey={item.uuid + "-" +  index}
          route={item}
          children={item.children}
          open={open}
          menuToggle={props.menuToggle}
          accordionHeight={props.accordionHeight}
          closer={props.closer}
        />

      })}
    </ul>
  )
}
export default Menu;
