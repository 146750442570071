import React from "react";

function IconChevron(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         style={props.style}
         className={props.className}
         height={props.height}
         width={props.width}
         viewBox="0 0 26 22"
    >
      <path fill={props.fill} d="M14.447 0c.457 0 .914.175 1.263.523l8.928 8.928a1.784 1.784 0 0 1 0 2.525l-8.928 8.929a1.784 1.784 0 0 1-2.525 0 1.784 1.784 0 0 1 0-2.525l7.665-7.666-7.665-7.666A1.784 1.784 0 0 1 14.447 0z"/>
      <path fill={props.fill} d="M1.948 0c.457 0 .914.175 1.262.523l8.928 8.928a1.784 1.784 0 0 1 0 2.525L3.21 20.905a1.784 1.784 0 0 1-2.525 0 1.784 1.784 0 0 1 0-2.525l7.666-7.666L.685 3.048A1.784 1.784 0 0 1 1.948 0z"/>
    </svg>
  );
}

export default IconChevron;
