import React from "react";
import {Link} from "react-router-dom";

class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: true};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {
    return (
      <span className={this.props.class} onClick={this.props.handler}>{this.props.iconBefore}{this.props.title}{this.props.iconAfter}</span>
    );
  }
}

export default Toggle;
