import React from "react";

function IconHamburger(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         className={props.className}
         width={props.width}
         height={props.height}
         viewBox="0 0 65 32"
    >

      <path fill={props.fill} d="M0 3c0-1.5 1.5-3 3-3h59c1.5 0 3 1.5 3 3s-1.5 3-3 3H3C1.5 6 0 4.5 0 3z"/>
      <path fill={props.fill} d="M0 16c0-1.5 1.5-3 3-3h59c1.5 0 3 1.5 3 3s-1.5 3-3 3H3c-1.5 0-3-1.5-3-3z"/>
      <path fill={props.fill} d="M0 29c0-1.5 1.5-3 3-3h59c1.5 0 3 1.5 3 3s-1.5 3-3 3H3c-1.5 0-3-1.5-3-3z"/>
    </svg>
  );
}

export default IconHamburger;
