import Route from "./Route";

class PromisingProgramRoute extends Route {

  getActiveMeshes() {
    return this.parent.getActiveMeshes();
  }
}

export default PromisingProgramRoute;
