import React, {useState, useRef} from 'react';
import IconPlus from "../assets/IconPlus";
import {Link} from "react-router-dom";

function MenuItem(props) {
  const content = useRef(null);
  const menuItemChildren = props.children
    ? props.children
      .sort((a,b) => a.position < b.position ? -1 : 1)
        .map((child, index) => (props.closer(<Link
          to={{pathname: child.currentPath, state: {fromMenu: true}}}
          key={child.uuid + '-' + index}>
          {child.pageData.title}
        </Link>)))
    : false;

  function toggleAccordion () {

    props.menuToggle(props.toggleKey, content.current.scrollHeight);

  }

  let setActive = props.open ? "active": "";
  let setHeight = props.open ? `${props.accordionHeight}px` : "0px";
  let rotation = props.open ? "rotate(90deg)" : "";

  let link;
  const to = {pathname: props.route.currentPath, state: {fromMenu: true}}
  if (menuItemChildren) {
    link = <Link className={'accordion pl-0 ' + setActive} to={to} onClick={toggleAccordion}>
      <div className="accordion__title">{props.route.pageData.title}
        <IconPlus
          style={{transform: rotation, transition: "transform 0.25s"}}
          height={"25px"}
          width={"25px"}
          className={"icon plus"}
          fill={"#fff"} />
      </div>
    </Link>;
  }
  else {
    link = <Link className={'accordion pl-0 ' + setActive} to={to} onClick={toggleAccordion}>
      <div className="accordion__title">{props.route.pageData.title}
      </div>
    </Link>;
  }

  // Conditionally wrap the Link with a closer if it has no children, otherwise leave off the closer.
  let wrappedParentLink = menuItemChildren ? link : props.closer(link);

  return (
    <li className="accordion__section">
      {wrappedParentLink}
      <div ref={content}
           style={{ maxHeight: setHeight}}
           className="accordion__content"
      >
        <div className="accordion__text">
          {menuItemChildren ? menuItemChildren : ''}
        </div>
      </div>
    </li>
  );
}
export default MenuItem;
