import React from "react";
import SectionTeaser from "./SectionTeaser";

const SidePage = function(props) {
    const title = typeof props.activeRoute.pageData.title !== "undefined" ? <h1 className={"sr-only"}>{props.activeRoute.pageData.title}</h1> : "";
    const children = props.activeRoute.children ? props.activeRoute.children.map((child, index) => {
        const color = typeof child.cubeState !== "undefined" ? child.cubeState.sectionSelected.color : "";
        return <div className={"section-wrap"} key={child.currentPath + index}><SectionTeaser title={child.pageData.title} link={child.currentPath} color={child.cubeState.sectionSelected.color} summary={child.pageData.summary} /></div>
    }) : "";
    return (
        <div className={"content-wrap side"}>
            {title}
            {children}
        </div>
    )
}

export default SidePage;
