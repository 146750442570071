import React from "react";
import SectionPage from "./SectionPage";
import StagePage from "./StagePage";
import DefaultPage from "./DefaultPage";
import BasicPage from "./BasicPage";
import SidePage from "./SidePage";
import ProgramPage from "./ProgramPage";
import ProgramInPracticePage from "./ProgramInPracticePage";
import SectionRoute from "../routes/SectionRoute";
import StageRoute from "../routes/StageRoute";
import FrontPageRoute from "../routes/FrontPageRoute";
import SidePageRoute from "../routes/SidePageRoute";
import SearchRoute from "../routes/SearchRoute";
import SearchContainer from "./SearchContainer";
import PromisingProgramRoute from "../routes/PromisingProgramRoute";
import EvidenceProgramRoute from "../routes/EvidenceProgramRoute";
import ProgramInPracticeRoute from "../routes/ProgramInPracticeRoute";
import SelfAssessmentReportPage from "./SelfAssessmentReportPage";
import SelfAssessmentReportPageRoute from "../routes/SelfAssessmentReportPageRoute";
import Spinner from "./Spinner";

function ContentPanel(props) {
  // when the activeRoute is falsey, display the spinner.
  if (!props.activeRoute) return <div className={"spinner-layout"}><Spinner/></div>;
  // Switch case won't work on complied code, since all constructors point to the `o` prototype
  if (props.activeRoute instanceof SectionRoute) {
    return <SectionPage activeRoute={props.activeRoute}/>;
  }
  else if (props.activeRoute instanceof StageRoute) {
    return <StagePage activeRoute={props.activeRoute} />;
  }
  else if (props.activeRoute instanceof FrontPageRoute) {
    return <BasicPage activeRoute={props.activeRoute} />;
  }
  else if (props.activeRoute instanceof SidePageRoute) {
    return <SidePage activeRoute={props.activeRoute}/>
  }
  else if (props.activeRoute instanceof SearchRoute) {
    return <SearchContainer activeRoute={props.activeRoute} history={props.history} setSearchCache={props.setSearchCache} searchCache={props.searchCache}/>
  }
  else if (props.activeRoute instanceof EvidenceProgramRoute) {
    return <ProgramPage activeRoute={props.activeRoute}/>
  }
  else if (props.activeRoute instanceof PromisingProgramRoute) {
    return <ProgramPage activeRoute={props.activeRoute}/>
  }
  else if (props.activeRoute instanceof ProgramInPracticeRoute) {
    return <ProgramInPracticePage activeRoute={props.activeRoute}/>
  }
  else if (props.activeRoute instanceof SelfAssessmentReportPageRoute) {
    return <SelfAssessmentReportPage activeRoute={props.activeRoute} location={props.location} />
  }
  else {
    return <DefaultPage activeRoute={props.activeRoute}/>;
  }

}

export default ContentPanel;
