import Route from "./Route";
import { Program, IRoute } from "./interfaces";

class SelfAssessmentReportPageRoute extends Route {
  constructor(node: Program) {
    const pageData = {
      title: node.attributes.title,
      field_display_title: node.attributes.field_display_title,
      body: node.attributes.body.processed,
      uuid: node.id,
    };

    super(pageData);

    this.side = -Math.PI / 2;
  }

  get pathSegment() {
    return "self-assessment-report";
  }

  searchByUUID(uuid: string): Array<IRoute> {
    const searchContainer = this.parent.getSearchContainer();
    if (!searchContainer) {
      throw new Error("No Search Container");
    }

    return searchContainer.searchByUUID(uuid);
  }

  getActiveMeshes() {
    return [];
  }
}

export default SelfAssessmentReportPageRoute;
