import React, {useState} from 'react';
import { Link } from "react-router-dom";
import Spinner from "./Spinner";
import IconSearch from "../assets/IconSearch";

const SearchForm = function(props) {
  let searchTerm = props.searchTerm ? props.searchTerm : '';
  return (
    <form className={"form-inline search-form mb-4 mt-4"} onSubmit={props.handleSearch}>
      <input className="form-control form-control-lg" placeholder="Search the ARK..." onChange={e => props.inputHandler(e)} type={"text"} value={searchTerm}/>
        <button type="submit" className={"btn btn-primary btn-no-icon btn-small ml-2 border-0 p-3 rounded"}>
          <IconSearch
            height={"30px"}
            width={"30px"}
            className={"icon search"}
            fill={"#141826"} />
        </button>
    </form>
  );

}

const ResultItem = function(props) {
  let crumbs = props.item.breadCrumbs.map((item, index) => {
    let crumbValue = index > 0 ? " | " + item.title : item.title
    return (<span key={"breadcrumb-" +index}>{crumbValue}</span>)

  });
  return (
    <li className={"list-group-item pr-0 pl-0"}>
      <span style={{color: props.item.color}} className={"search-breadcrumb text-capitalize"}>{crumbs}</span>
      <h3>
        <Link
         to={{
          pathname: props.item.src,
          state: {spinCube: true}
        }}
        >{props.item.title}</Link>
      </h3>
      <div className={"description"}>{props.item.summary}</div>
    </li>
  )
};


const SearchPage = function(props) {

  const sortFunction = (a,b) => {
    return b.type && b.type === 'program' ? 1 : -1;
  }
  let body = props.searching
    ? <div className={"spinner-layout"}><Spinner/></div>
    : <ul className={"list-group list-group-flush"}>{props.results.sort(sortFunction).map((item, index) => <ResultItem key={item.id + index} item={item}/>)}</ul>

  let resultsText = props.searchPerformed ? props.results.length + ' results found.' : '';

  return (<div>
      <SearchForm handleSearch={props.handleSearch} searchTerm={props.searchTerm} inputHandler={props.inputHandler}/>
      {resultsText}
      {body}
  </div>
  )
};


export default SearchPage;
