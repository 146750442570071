import React from "react";
import BodyParser from "./BodyParser";
import Breadcrumbs from "./Breadcrumbs";
import RelatedDocuments from "./RelatedDocuments";

const ProgramInPractice = function(props) {
  const title = typeof props.activeRoute.pageData.title !== "undefined" ? <h1>{props.activeRoute.pageData.title}</h1> : "";
  return (
    <div className={"content-wrap program-in-pratice"}>
    <div className={"d-flex"}>
      <Breadcrumbs url={props.activeRoute.parent.parent.currentPath} color={props.activeRoute.parent.color} title={props.activeRoute.parent.parent.pageData.title}/>
      <span className={"breadcrumb-separator"}></span>
      <Breadcrumbs url={props.activeRoute.parent.currentPath} color={props.activeRoute.parent.color} title={props.activeRoute.parent.pageData.title}/>
    </div>
      {title}
      <h4 className={'card-author-type'}>{props.activeRoute.pageData.author_type}</h4>
      <BodyParser body={props.activeRoute.pageData.body} />
      <RelatedDocuments files={props.activeRoute.pageData.fetched_related_documents} />
    </div>
  )
}

export default ProgramInPractice;
