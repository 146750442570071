import React from "react";
import BodyParser from "./BodyParser";

const BasicPage = function(props) {
  const title = typeof props.activeRoute.pageData.title !== "undefined" ? <h1>{props.activeRoute.pageData.title}</h1> : "";
  return (
    <div className={"content-wrap basic"}>
      {title}
      <BodyParser body={props.activeRoute.pageData.body}/>
    </div>
  )
}

export default BasicPage;
