import { CubeState, IRoute, PageData, RouteInstantiater } from "./interfaces";
import flatten from '../utilities/arrayFlatten';

class Route implements IRoute {
  private _parent: IRoute;
  private _children: Array<IRoute>
  cubeState: CubeState;
  pageData: PageData;
  subRoutes?: { [key: string]: IRoute; };
  relatedEndpoint?: string
  type?: string
  _pathSegment?: string
  color?: string
  uuid: string
  discriminator: string;
  // between 0 and PI representing right to left
  side?: number

  constructor(routeData: RouteInstantiater) {
    this.pageData = routeData;
    this.uuid = routeData.uuid;
    this.discriminator = "Route";
    this.side = null;
  }

  get currentPath() {

    const pathArray = this.getAncenstors(this, []);
    let acc = "";
    pathArray.forEach(item => {
      acc = acc + item.pathSegment + "/";
    });

    return acc;
  }

  set parent(parent: IRoute) {
    this._parent = parent;
    if (!parent.children) {
      parent.children = [];
      parent.children.push(this);
    } else {
      parent.children.push(this)
    }
  }

  get parent(): IRoute {
    return this._parent ? this._parent : null;
  }

  get children() {
    return this._children ? this._children : null;
  }

  set children(children: Array<IRoute>) {
    this._children = children;
  }

  get pathSegment() {
    return this.pageData.title
      ? this.pageData.title.toLowerCase().trim().replace(/\s+|\//g, '-')
      : this._pathSegment;
  }

  set pathSegment(pathString: string) {
    if (!this.pageData.title) {
      this._pathSegment = pathString;
    } else {
      throw new Error("don't set paths when title is available.")
    }
  }

  setCubeState(cubeState: CubeState) {
    this.cubeState = cubeState;
  }

  private getAncenstors(route: IRoute, pathArray: Array<IRoute>): Array<IRoute> {
    if (!route.parent) {
      pathArray.unshift(route);
      return pathArray;
    }
    pathArray.unshift(route)

    return this.getAncenstors(route.parent, pathArray);
  }

  getRouteToCurrentPage(pathArray: Array<string>, routes: Array<IRoute>): Array<IRoute> {
    const currentPathSegment = pathArray.shift();
    routes.push(this);

    if (pathArray.length === 0) return routes;

    const nextChild = this.children.find(child => child.pathSegment === pathArray[0]);

    if (!nextChild) throw new Error("Bad Route");

    return nextChild.getRouteToCurrentPage(pathArray, routes);

  }

  async getCurrentRoute(path: string): Promise<IRoute> {
    // Change the signature so we can pass path strings in, especially the root string "/"
    const pathArray = this.splitPath(path);
    return this.recursiveGetCurrentRoute(pathArray, this);
  }

  private splitPath(path: string): Array<string> {
    return path.split("/")
      // When splitting on the root route, "/", we get an array of empty strings: [ "", "" ],
      // We want to return an array with just the root. This is accomplished with some type-coercion and reduction.
      .reduce((a: Array<string>, el, i) => {
        if (el || !i) a.push(el);
        return a;
      }, [])
  }

  recursiveGetCurrentRoute(pathArray: Array<string>, currentRoute: IRoute): Promise<IRoute> {
    pathArray.shift();

    // Because we sometimes return a Promise if we need to fetch this Route's data, we make them all return a Promise
    if (pathArray.length === 0) return new Promise((resolve, reject) => {
      return resolve(currentRoute)
    });

    const nextChild = currentRoute.children?.find?.(child => child.pathSegment === pathArray[0]);

    if (!nextChild) {
      //throw new Error("Bad Route");
      return;
    }

    return nextChild.recursiveGetCurrentRoute(pathArray, nextChild);
  }

  getCubeSide() {
    // @todo because we're introducing side-less pages, like search, we should be querying the current cube state
    //  instead of the Route branch this Route belongs to.
    // if this is the top of the tree, the default front-facing side is the correct site.
    if (!this.parent) return this.side;

    return this.recursiveFindCubeSide(this);

  }

  private recursiveFindCubeSide(route: IRoute): number {
    if (route.side !== null) return route.side;

    return this.recursiveFindCubeSide(route.parent);
  }

  /**
   * @deprecated This may be useful, but it's a recursive tree search and will get slower as a user explores and expands
   * the tree. Instead, use the SearchRoute.
   * @param uuid
   */
  findByUUID(uuid) {
    let treeTop = this.getTreeTop(this);
    let flatArray = flatten(this.recursiveFindByUUID(treeTop, uuid));
    return flatArray.filter(item => item);
  }

  getTreeTop(route: IRoute) {
    if (!route.parent) {
      return route;
    } else {
      return this.getTreeTop(route.parent);
    }
  }

  private recursiveFindByUUID(route: IRoute, uuid: string) {
    if (route.uuid === uuid) {
      return route;
    } else if (route.children && route.children.length > 0) {
      return route.children.map(child => {
        return this.recursiveFindByUUID(child, uuid);
      });
    } else {
      return null;
    }
  }

  getBreadCrumb() {

    let pathArray = this.getAncenstors(this, []);
    let SectionIndex = pathArray.findIndex(route => (route.discriminator === "Section"));
    // We aren't using the breadcrumb color at the moment.
    return pathArray.slice(SectionIndex).map(route => ({
      title: route.pageData.title,
      color: route.getColor()
    }))
  }

  instanceDiscriminator(type: string): boolean {
    return type === this.discriminator;
  }

  getColor() {
    return null;
  }

  getActiveMeshes() {
    return [];
  }

  getSearchContainer() {
    return this._children.find(child => child.pathSegment === "search")
  }
}

export default Route;
