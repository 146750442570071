import React from "react";
import * as BABYLON from 'babylonjs';

export default class CubeCanvas extends React.Component {
    constructor(props) {
      super(props);
      this.canvas = React.createRef();
      this.initialCanvasSize = {};
      this.setWidthHeight(this.initialCanvasSize);
    }

    onResizeWindow = () => {
        this.setWidthHeight(this.canvas);
        if (this.engine) {
            this.engine.resize();
        }
    }

    setWidthHeight(canvas) {
      const width = window.innerWidth;
      const height = window.innerHeight;
      // Instead of messing with browser detection, or pixel widths, let's say any vertical display get's the stack.
      if (height/width > 1) {
        canvas.width = width;
        canvas.height = Math.floor(height * 0.5);
      }
      else {
        canvas.width = Math.floor(width * 0.618);
        canvas.height = height;
      }
    }
    componentDidMount () {
        this.engine = new BABYLON.Engine(
            this.canvas,
            true,
            this.props.engineOptions,
            this.props.adaptToDeviceRatio
        );

        let scene = new BABYLON.Scene(this.engine);
        this.scene = scene;

        if (typeof this.props.onSceneMount === 'function') {
            this.props.onSceneMount({
                scene,
                engine: this.engine,
                canvas: this.canvas,
                initializeSections: this.initializeSections
            });

        } else {
            console.error('onSceneMount function not available');
        }

        // Resize the babylon engine when the window is resized
        window.addEventListener('resize', this.onResizeWindow);
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.onResizeWindow);
    }

    onCanvasLoaded = (c) => {
        if (c !== null) {
            this.canvas = c;
        }
    }

    render () {
        // 'rest' can contain additional properties that you can flow through to canvas:
        // (id, className, etc.)
        let { width, height, ...rest } = this.props;

        let opts = {...rest, ...this.initialCanvasSize};

        // Filter out any objects or functions
        opts = Object.keys(opts).reduce((acc, option) => {
            if(typeof opts[option] !== "object" && typeof opts[option] !== "function") {
                acc[option] = opts[option];
            }
            return acc
        }, {});

        return (
            <canvas
                {...opts}
                ref={this.onCanvasLoaded}
                onClick={()=> {this.props.setCubeGuidance(false)}}
            />
        )
    }
}
