import React from 'react';

function IconCross(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         className={props.className}
         height={props.height}
         width={props.width}
         viewBox="0 0 20 20"
    >
      <path fill={props.fill} d="M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z"/>
    </svg>
  );
}

export default IconCross;
