import React from "react";
import {Link} from "react-router-dom";
import IconChevron from "../assets/IconChevron";

function SectionTeaser(props) {
  return (
    <div>
      <h2 style={{color:props.color }}>{props.title}</h2>
      <div className={"summary mb-2"}>{props.summary}</div>
      <Link className={"btn-link"} to={props.link}>{props.title}<IconChevron height={"20px"} width={"20px"} className={"icon chevron ml-2"} fill={"#1e558d"} /></Link>
    </div>
  );
}
export default SectionTeaser;
