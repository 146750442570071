import React from "react";

function IconInfo(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         className={props.className}
         height={props.height}
         width={props.width}
         viewBox="0 0 25 24"
    >
    <path fill={props.fill} d="M13.445 5.94a1.007 1.007 0 1 1-2.013 0 1.007 1.007 0 0 1 2.013 0z"/>
    <path fill={props.fill} d="M12.439 19.028a.755.755 0 0 1-.756-.755V10.47h-1.006a.755.755 0 0 1 0-1.51h1.762c.416 0 .755.338.755.755v8.558a.755.755 0 0 1-.755.755z"/>
    <path fill={props.fill} d="M23.01 11.98c0 5.83-4.742 10.572-10.571 10.572-5.83 0-10.572-4.742-10.572-10.572 0-5.83 4.742-10.571 10.572-10.571 5.83 0 10.571 4.742 10.571 10.571zm1.51 0C24.52 5.318 19.1-.102 12.44-.102 5.776-.102.357 5.318.357 11.98c0 6.662 5.42 12.082 12.082 12.082 6.662 0 12.081-5.42 12.081-12.082z"/>
    <path fill={props.fill} d="M14.704 19.028h-4.53a.755.755 0 0 1 0-1.51h4.53a.755.755 0 0 1 0 1.51z"/>
    </svg>
  );
}

export default IconInfo;
