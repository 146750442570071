import jsonFetch from "../utilities/cubeFetch";
import {
  IRoute,
  Section,
  DrupalNode,
  RouteTree,
  SidePage,
  Stage,
  Program,
  BasicPage,
} from "./interfaces";
import gradientGenerator from "../utilities/gradientGenerator";
import * as BABYLON from "babylonjs";
import extractNodeFromTreePath from "./extractNodeFromTreePath";
import { sectionBoxInitiator } from "../webgl-components/interfaces";
import selectRouteFromTree from "./selectRouteFromTree";
import FrontPageRoute from "./FrontPageRoute";
import SidePageRoute from "./SidePageRoute";
import SectionRoute from "./SectionRoute";
import StageRoute from "./StageRoute";
import SearchRoute from "./SearchRoute";
import MenuRoute from "./MenuRoute";
import SelfAssessmentReportPageRoute from "./SelfAssessmentReportPageRoute";

export default class RouteBuilder {
  routeScaffold: RouteTree;
  treeTop: IRoute;
  sectionPage: string;
  sectionArray: Array<any>;

  constructor() {}

  async build(): Promise<RouteBuilder> {
    this.treeTop = await this.fetchFrontPage();
    const sidePageNodes = await this.fetchSidePages(
      this.treeTop.relatedEndpoint
    );
    this.createSidePages(sidePageNodes);
    await this.fetchSections(this.treeTop);
    await this.fetchAndBuildStages(this.treeTop);

    // "Hard-coded" routes follow.
    const searchInitiator = {
      body: "",
      uuid: "not-content",
    };
    const searchPage = new SearchRoute(searchInitiator);
    searchPage.pathSegment = "search";
    searchPage.parent = this.treeTop;
    searchPage.createSearchIndex();

    const selfAssessmentReportPage = await this.fetchSelfAssessmentReportPage();
    selfAssessmentReportPage.parent = this.treeTop;

    // @todo This is a good place to note. Routes should be able to be instantiated with only a uuid and bundle.
    // Only when they are viewed should they fetch their content. This was the original idea, but was set aside when
    // I realized the uuids and content came in one piece when fetching against the /jsonapi/node/{bundle} endpoint.
    // Better would be to build all the content tree as a Menu in Drupal, and fetch just the uuid's/bundles from the
    // custom menu endpoint (or an extended menu endpoint from jsonapi), build the RouteTree using just the menu data,
    // and fetch and build the content only on page navigation.

    // Currently this depends on nodes being in the menu. However, we also would like webforms to be in the menu
    // and thus we should take out the depenendency of nodes from within this code.
    let response = await jsonFetch("/drupal/ark_menu/menu");
    let menuRoutes = response.data.map((item) => {
      return MenuRoute.createRoutes(item);
    });

    let menuPages = await Promise.all(menuRoutes);
    menuPages.forEach((menuPage) => {
      // @ts-ignore
      menuPage.parent = this.treeTop;
    });

    // Nomination Form -- this should not be necessary if the above menu could handle things other than nodes.
    const iframeString =
      "<iframe\n" +
      '        src="/drupal/webform/nomination/share"\n' +
      '        title="Nomination | nadcp"\n' +
      '        className="webform-share-iframe"\n' +
      '        frameborder="0"\n' +
      '        allow="geolocation; microphone; camera"\n' +
      "        allowfullscreen='true'\n" +
      "        style={'min-width:100%;width:1px;height:2400px;'}\n" +
      "      />\n" +
      '      <script src="//cdn.jsdelivr.net/gh/davidjbradshaw/iframe-resizer@4.2.10/js/iframeResizer.min.js"/>\n' +
      "      <script>iFrameResize({}, '.webform-share-iframe');</script>";
    const nominationForm = new MenuRoute({
      title: "Nomination Form",
      body: iframeString,
      uuid: "non-content",
    });
    nominationForm.parent = this.treeTop;

    // Program in Pratice Form
    const pipIFrameString =
      "<iframe\n" +
      '        src="/drupal/webform/programs_in_practice/share"\n' +
      '        title="Program In Practice Submission | nadcp"\n' +
      '        className="webform-share-iframe"\n' +
      '        frameborder="0"\n' +
      '        allow="geolocation; microphone; camera"\n' +
      "        allowfullscreen='true'\n" +
      "        style={'min-width:100%;width:1px;height:2400px;'}\n" +
      "      />\n" +
      '      <script src="//cdn.jsdelivr.net/gh/davidjbradshaw/iframe-resizer@4.2.10/js/iframeResizer.min.js"/>\n' +
      "      <script>iFrameResize({}, '.webform-share-iframe');</script>";
    const pipForm = new MenuRoute({
      title: "Program in Practice Submission",
      body: pipIFrameString,
      uuid: "non-content",
    });
    pipForm.parent = this.treeTop;

    // Self Assessment
    const selfAssessmentIFrame =
      "<iframe\n" +
      "src=\"/drupal/webform/self_assessment/share\"\n" +
      "title=\"Self Assessment | NADCP\"\n" +
      "className=\"webform-share-iframe\"\n" +
      "frameborder=\"0\"\n" +
      "        allow=\"geolocation; microphone; camera\"\n" +
      "        allowfullscreen='true'\n" +
      "        style={'min-width:100%;width:1px;height:2400px;'}\n" +
      "      />\n" +
      "      <script src=\"//cdn.jsdelivr.net/gh/davidjbradshaw/iframe-resizer@4.2.10/js/iframeResizer.min.js\"/>\n" +
      "      <script>iFrameResize({}, '.webform-share-iframe');</script>";
    const saForm = new MenuRoute({ title: "Self Assessment", body: selfAssessmentIFrame, uuid: "non-content" });
    saForm.parent = this.treeTop;

    return this;
  }

  private async fetchFrontPage(): Promise<FrontPageRoute> {
    return jsonFetch(
      "/drupal/jsonapi/node/page?filter[title][value]=Welcome%20to%20the%20ARK"
    )
      .then((res) => {
        // For the moment, we're finding the first page with the name "Welcome to the Ark" (case insensitive)
        return res.data.find(
          (page: DrupalNode) =>
            page.attributes.title.toUpperCase().replace(/\s+/g, "") ===
            "WELCOMETOTHEARK"
        );
      })
      .then((frontPage) => {
        return this.buildFrontPage(frontPage);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  private async fetchSelfAssessmentReportPage(): Promise<SelfAssessmentReportPageRoute> {
    return jsonFetch(
      "/drupal/jsonapi/node/page?filter[title][value]=Online%20Self-Assessment%20Tool%20Report"
    )
      .then((res) => {
        return res.data.find((page: DrupalNode) => 1);
      })
      .then((selfAssessmentReportPage) => {
        return this.buildSelfAssessmentReportPage(selfAssessmentReportPage);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  private async fetchSidePages(href: string): Promise<Array<SidePage>> {
    const res = await jsonFetch(href);
    return res.data;
  }

  // Instantiate SidePageRoutes using the array of sidepages
  private createSidePages(pages: Array<SidePage>): Array<SidePageRoute> {
    return pages.map((page) => {
      const route = new SidePageRoute(page);
      route.parent = this.treeTop;
      return route;
    });
  }

  private async fetchSections(treeTop: IRoute) {
    const hr = await treeTop.getCurrentRoute("/high-risk");

    const lr = await treeTop.getCurrentRoute("/low-risk");

    const highRiskSections = await jsonFetch(hr.pageData.relatedEntities);
    const lowRiskSections = await jsonFetch(lr.pageData.relatedEntities);

    this.makeSections(hr, highRiskSections.data);
    this.makeSections(lr, lowRiskSections.data);
  }

  private makeSections(sideRoute: SidePageRoute, sections: Array<Section>) {
    sections.forEach((section) => {
      const sectionRoute = new SectionRoute(section);
      sectionRoute.parent = sideRoute;
      sectionRoute.cubeState = {
        sectionSelected: this.buildInitiatorSection(section),
      };
    });
  }

  private async fetchAndBuildStages(route: IRoute) {
    const sections = await this.getSections(route);

    const thing = sections.map(async (section) => {
      const gradient = gradientGenerator(
        section.cubeState.sectionSelected.color
      );
      // section.subRoutes = {};
      const stages = await jsonFetch(section.pageData.relatedEntities);
      stages.data.forEach((stage: Stage, index: number) => {
        // Create the Route
        // const pathString = this.makeTitlePath(stage.attributes.field_display_title);
        const route = new StageRoute(stage);
        route.color = "#" + gradient[index];
        // @todo this indicates there's something wrong with the object structures
        route.setCubeState({});
        route.parent = section;
      });
    });
    // By reference, so we're returning the scaffold to itself
    return Promise.all(thing).then(() => this.treeTop);
  }

  private buildFrontPage(node: BasicPage): FrontPageRoute {
    const frontPage = new FrontPageRoute(node);
    frontPage.setCubeState({
      position: new BABYLON.Vector3(0, 2.4, -Math.PI * Math.PI),
    });
    frontPage.relatedEndpoint =
      node.relationships.field_side_page.links.related.href;

    return frontPage;
  }

  private buildSelfAssessmentReportPage(
    node: Program
  ): SelfAssessmentReportPageRoute {
    const selfAssessmentReportPage = new SelfAssessmentReportPageRoute(node);
    selfAssessmentReportPage.setCubeState({
      position: new BABYLON.Vector3(0, 2.4, -Math.PI * Math.PI),
    });

    return selfAssessmentReportPage;
  }

  private buildInitiatorSection(node: Section): sectionBoxInitiator {
    return {
      name: node.attributes.title,
      // @todo don't fake in a value
      position: node.attributes.field_block_position
        ? JSON.parse(node.attributes.field_block_position)
        : {
            x: 1,
            y: 1,
          },
      color: node.attributes.field_section_block_color
        ? node.attributes.field_section_block_color.color
        : "#222222",
      dimensions: {
        width: 2,
        height: 2,
        depth: 5,
      },
      // clickHandler: ()=>{},
      pageBody: node.attributes.body.processed,
    };
  }

  // Does not return by reference.
  extractByPath(path: string) {
    const pathArray = path.split("/").map((part) => "/" + part);
    return extractNodeFromTreePath(pathArray, this.routeScaffold, {});
  }

  async getSections(route: IRoute) {
    return Promise.all([
      route.getCurrentRoute("/high-risk/high-need"),
      route.getCurrentRoute("/high-risk/low-need"),
      route.getCurrentRoute("/low-risk/high-need"),
      route.getCurrentRoute("/low-risk/low-need"),
    ]);
  }

  getByPath(path: string): IRoute {
    const pathArray = path
      .split("/")
      // When splitting on the root route, "/", we get an array of empty strings: [ "", "" ],
      // We want to return an array with just the root. This is accomplished with some type-coercion and reduction.
      .reduce((a: Array<string>, el, i) => {
        if (el || !i) a.push(el);
        return a;
      }, [])
      .map((part) => "/" + part);
    return selectRouteFromTree(pathArray, this.routeScaffold);
  }

  private getPathArrayToSection(title: string) {
    const titleArray = title.split("&");
    return titleArray.map(this.makeTitlePath);
  }

  private makeTitlePath(title: string) {
    return (
      "/" +
      title
        .toLowerCase()
        .trim()
        .replace(/\s+|\//g, "-")
    );
  }
}
