import * as React from 'react';
import * as BABYLON from 'babylonjs';

import CubeCanvas from './CubeCanvas';
import SectionBoxFactory from "../webgl-components/SectionBoxFactory";
import CameraDragBehavior from "../utilities/CameraDragBehavior";
import SectionBox from "../webgl-components/SectionBox";
import StageBox from "../webgl-components/StageBox";

export default class CubeScene extends React.Component {
    constructor(props) {
      super(props);
      this.state = {hoverCube: false};
    }
    onSceneMount = (e) => {
        const { canvas, scene, engine } = e;
        this.props.registerScene(scene);
        // scene.debugLayer.show();

        scene.clearColor = new BABYLON.Color3.FromHexString("#e4e0e0");
        scene.ambientColor = new BABYLON.Color3.FromHexString("#969494");

        // This creates and positions a arc rotation camera (non-mesh)
        const camera = new BABYLON.ArcRotateCamera("Camera",  -Math.PI/2,Math.PI/2-0.25, 10, BABYLON.Vector3.Zero(), scene);
        // This attaches the camera to the canvas
        camera.attachControl(canvas, true);
        camera.actionManager = new BABYLON.ActionManager(scene);

        // Camera limits
        camera.lowerRadiusLimit = 8;
        camera.upperRadiusLimit = 16;
        camera.lowerAlphaLimit = -3.4;
        camera.upperAlphaLimit = 0.25;
        camera.lowerBetaLimit = 1;
        camera.upperBetaLimit = 2;

        const animationX = new BABYLON.Animation(
          "cubeSpin1",
          "alpha",
          30,
          BABYLON.Animation.ANIMATIONTYPE_FLOAT,
          BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
        );

      const animationY = new BABYLON.Animation(
        "cubeSpin2",
        "beta",
        30,
        BABYLON.Animation.ANIMATIONTYPE_FLOAT,
        BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
      );

        const easingFunction = new BABYLON.CubicEase();

        easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

        // Adding the easing function to the animation
        animationX.setEasingFunction(easingFunction);
        animationY.setEasingFunction(easingFunction);

        camera.animations = [animationX, animationY];

        // Lights
        const hemisphericLight = new BABYLON.HemisphericLight(
          "hemiLight",
          new BABYLON.Vector3(0, 10, 0),
          scene);
        hemisphericLight.intensity = 0.33;

        const directionLight = new BABYLON.DirectionalLight(
          "directionLight",
          new BABYLON.Vector3(0, -12, 0),
          scene);
        directionLight.specular = new BABYLON.Color3(0.75,0.5,0)
        directionLight.intensity = 0.12;

        const pointLight1 = new BABYLON.PointLight("pointLight1", new BABYLON.Vector3(-8, 24, 0), scene);
        pointLight1.specular = new BABYLON.Color3(0.75,0.5,0);
        pointLight1.intensity = 0.1;

        const pointLight2 = new BABYLON.PointLight("pointLight2", new BABYLON.Vector3(8, 24, 0), scene);
        pointLight2.specular = new BABYLON.Color3(0.75,0.5,0);
        pointLight2.intensity = 0.1;


        // Create the four section meshes.
        //#6281CE; #67C089; #FF935C; #CE324A
        let sectionList = [];
        this.props.initializeSections.forEach(section => {
            const sectionFactory = new SectionBoxFactory(section, scene);
            const threeDSection = sectionFactory.createBox();
            section.cubeState.selectedSection = threeDSection;

            sectionList.push(threeDSection);
          })

        // //Our built-in 'ground' shape. Params: name, width, depth, subdivs, scene
        const ground = BABYLON.Mesh.CreateGround("ground1", 600, 600, 2, scene);
        ground.position = new BABYLON.Vector3(0, -4, 0);
        ground.receiveShadows = true;
        const backgroundMaterial = new BABYLON.StandardMaterial("groundMaterial", scene);
        backgroundMaterial.ambientColor = new BABYLON.Color3(0.7,0.7, 0.7);
        ground.material = backgroundMaterial;


        // Shadows
        const shadowGenerator1 = new BABYLON.ShadowGenerator(1024, pointLight1);
        let [one,two, three, four] = sectionList;
        shadowGenerator1.addShadowCaster(one);
        shadowGenerator1.addShadowCaster(two);
        shadowGenerator1.addShadowCaster(three);
        shadowGenerator1.addShadowCaster(four);
        shadowGenerator1.usePoissonSampling = true;
        shadowGenerator1.useExponentialShadowMap = true;
        shadowGenerator1.useBlurExponentialShadowMap = true;

        const shadowGenerator2 = new BABYLON.ShadowGenerator(1024, pointLight2);
        shadowGenerator2.addShadowCaster(one);
        shadowGenerator2.addShadowCaster(two);
        shadowGenerator2.addShadowCaster(three);
        shadowGenerator2.addShadowCaster(four);
        shadowGenerator2.usePoissonSampling = true;
        shadowGenerator2.useExponentialShadowMap = true;
        shadowGenerator2.useBlurExponentialShadowMap = true;


      const shadowGenerator3 = new BABYLON.ShadowGenerator(1024, directionLight);
      shadowGenerator3.addShadowCaster(one);
      shadowGenerator3.addShadowCaster(two);
      shadowGenerator3.addShadowCaster(three);
      shadowGenerator3.addShadowCaster(four);
      shadowGenerator3.usePoissonSampling = true;
      // shadowGenerator.useExponentialShadowMap = true;
      shadowGenerator3.useBlurExponentialShadowMap = true;




        // Click handler
        scene.onPointerObservable.add((pointerInfo) => {

            if (
              pointerInfo.type === BABYLON.PointerEventTypes.POINTERPICK
              && typeof pointerInfo.pickInfo !== 'undefined'
              && pointerInfo.pickInfo.pickedMesh.name !== "ground1"
            ) {
                // @todo this checking logic maybe could be done interior to the box objects
                if (typeof pointerInfo.pickInfo.pickedMesh.selectBox !== "function") {

                    pointerInfo.pickInfo.pickedMesh.parent.selectBox();
                }
                else {
                    pointerInfo.pickInfo.pickedMesh.selectBox();
                }
            }

        });

      scene.registerBeforeRender(() => {
        const pickResult = scene.pick(scene.pointerX, scene.pointerY,
          function(mesh) { return mesh.isVisible && mesh.isReady() && mesh.name !== "ground1"},
          false,
          camera);

        function resetAllMovableMesh() {
          const meshes = scene.getMeshesByTags("movable");
          const allMeshes = meshes.reduce((collector, currentMesh) => {
            const children = currentMesh.getChildMeshes();
            return collector.concat(children);
          }, []);

          allMeshes.forEach(mesh => {
            mesh.renderOverlay = false;
          });
          return allMeshes;
        }

        // When we move out of the cube
        if (!pickResult.hit) {
          // check if we're still hovering
          if(this.state.hoverCube) {
            // reset all the meshes
            resetAllMovableMesh();
            // and set hoving to false
            this.setState({hoverCube: false});
          }
        }
        else {

          const allMeshes = resetAllMovableMesh();
          const currentMesh = pickResult.pickedMesh

          if (allMeshes.includes(currentMesh)) {
            // prevent continuous re-renders
            if (!this.state.hoverCube) {
              this.setState({hoverCube: true});
            }
            currentMesh.overlayColor = new BABYLON.Color3(255,255,255);
            currentMesh.overlayAlpha = 0.125;
            currentMesh.renderOverlay = true;
          }
        }
      });

        new CameraDragBehavior(camera, this.props.setPage);

        engine.runRenderLoop(() => {
            if (scene) {
                scene.render();
            }
        });

    }

    rotateCamera(camera) {

    }

    render() {
        return this.props.initializeSections.length > 0
          ? (<div className={"canvas-wrapper"}>
                <CubeCanvas
                  initializeSections={this.props.initializeSections}
                  onSceneMount={this.onSceneMount}
                  className={this.state.hoverCube ? "hover" : ""}
                  setCubeGuidance={this.props.setGuidance}
                />
            </div>)
          : null; // @todo spinner!
    }
}

