import Route from "./Route";
import {SidePage} from "./interfaces"

class SidePageRoute extends Route {

  side: number;

  constructor(node: SidePage) {

    const pageData = {
      title: node.attributes.title,
      body: node.attributes.body ? node.attributes.body.processed: '',
      relatedEntities: node.relationships.field_section_link.links.related.href,
      uuid: node.id
    }


    super(pageData);

    this.addCubeState(this.pathSegment);
  }

  addCubeState(pathSegment: string) {
    if(pathSegment === "high-risk") {
      this.side = -3.14;
    }
    else {
      this.side = 0;
    }
  }

  getActiveMeshes() {
    return [];
  }

}

export default SidePageRoute;
