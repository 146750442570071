import * as BABYLON from 'babylonjs';
import {Nullable} from "babylonjs/types";
import implementClosestPlane from "./implementClosestPlane";

// A behavior to allow events to fire on drag
class CameraDragBehavior implements BABYLON.Behavior<BABYLON.Camera> {

  private _pointerObserver: Nullable<BABYLON.Observer<BABYLON.PointerInfo>>;
  private _scene: BABYLON.Scene;
  detach: () => void;
  public setState: Function;


  /**
   *  Fires each time the attached camera is dragged with the pointer
   *  * delta between last drag position and current drag position in world space
   *  * dragDistance along the drag axis
   *  * dragPlaneNormal normal of the current drag plane used during the drag
   *  * dragPlanePoint in world space where the drag intersects the drag plane
   */
  public onDragObservable = new BABYLON.Observable<{ delta: BABYLON.Vector3, dragPlanePoint: BABYLON.Vector3, dragPlaneNormal: BABYLON.Vector3, dragDistance: number, pointerId: number }>();

  public dragging = false;

  constructor(camera: BABYLON.ArcRotateCamera, setState: Function) {
    this.attach(camera);
    this.setState = setState;
  }

  /**
   *  The name of the behavior
   */
  public get name(): string {
    return "CameraPointerDrag";
  }
  /**
   *  Initializes the behavior
   */
  public init() { }

  public attach(camera: BABYLON.ArcRotateCamera) : void
  {
    this._scene = camera.getScene();

    this._pointerObserver = this._scene.onPointerObservable.add((pointerInfo, eventState) => {

      if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERDOWN) {

        if (this.dragging) {
          return;
        }
        // const closestPlane = implementClosestPlane(camera);
        // this.setState(closestPlane);
        this.dragging = true;
      }
      else if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERUP) {
        // const closestPlane = implementClosestPlane(camera);
        // this.setState(closestPlane);
        this.dragging = false;
      }
      else if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERMOVE) {

        if(!this.dragging) {
          return;
        }
        const closestPlane = implementClosestPlane(camera);
        this.setState(closestPlane);
      }
    });
  }
}

export default CameraDragBehavior;
