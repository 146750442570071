import React, {Component} from "react";
import MenuRoute from "../routes/MenuRoute";
import Menu from "./Menu";

class MenuContainer extends Component {

  constructor(props) {
    super(props);

    this.state ={ menus: [], openMenu: null, accordionHeight: 0 }
    this.menuToggle = this.menuToggle.bind(this);
  }

  menuRouteFilter(route) {
    // if we're on a bad Route, route is undefined
    if (!route) {window.location = "/"}
    let treeTop = route.getTreeTop(route);
    return treeTop.children.filter(item => item instanceof MenuRoute)
  }

  menuToggle(key, height) {

    if (this.state.openMenu === key) {
      this.setState({ openMenu: null, accordionHeight: 0 })
    }
    else {
      this.setState({ openMenu: key, accordionHeight: height });
    }

  }

  componentDidUpdate(prevProps) {
    // We only populate the menu with Routes(top-level only), that are of type MenuRoute.

    if (prevProps.flyoutVar !== this.props.flyoutVar) {
      let menus = this.menuRouteFilter(this.props.activeRoute);

      this.setState({menus})
    }

  }


  render() {
    return this.state.menus.length > 0
      ? <Menu
        menu={this.state.menus}
        openMenu={this.state.openMenu}
        menuToggle={this.menuToggle}
        accordionHeight={this.state.accordionHeight}
        closer={this.props.closer}
      />
      : '';
  }

}
export default MenuContainer;
