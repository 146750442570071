import Route from "./Route";
import {Section} from "./interfaces"

// There are:
// Two Side Pages: (High Risk and Low Risk) (Links on the bottom of the page.
// Four Section Pages: (High Risk High Need, Low Risk High Need, Low Risk & Low Need, High Risk & Low Need

class SectionRoute extends Route {
  discriminator: string;

  constructor(node: Section) {

    const pageData = {
      title: node.attributes.title,
      body: node.attributes.body.processed,
      relatedEntities: node.relationships.field_stage.links.related.href,
      summary: node.attributes.body.summary,
      uuid: node.id
    }

    super(pageData);
    this.discriminator = "Section";
  }

  get pathSegment() {
    // Section titles contain the side title as well, i.e. High Risk & High Need, which needs to result in /high-need
    // in the path.
    return this.pageData.title.split("&")[1].trim().toLowerCase().replace(/\s+/g, '-')
  }

  getParentColor() {
    return null;
  }

  getColor() {
    return this.cubeState.sectionSelected.color
  }

  getActiveMeshes() {
    return [this.cubeState.selectedSection];
  }

}

export default SectionRoute;
