
import React from "react";

function RotateIcon() {

return (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
       viewBox="0 0 62 56" height={"92px"} >
<g>
	<g>
		<path d="M52.7,23.4c-0.4-0.3-1-0.1-1.2,0.3s-0.1,1,0.3,1.2c0,0,0,0,0,0c4.9,2.8,7.7,6.5,7.7,10.3c0,7.4-10.4,13.8-24.2,15
			c-0.5,0.1-0.8,0.5-0.8,1c0.1,0.5,0.5,0.8,0.9,0.8c7-0.6,13.5-2.5,18.2-5.5c4.9-3.1,7.6-7.1,7.6-11.3
			C61.3,30.7,58.2,26.5,52.7,23.4z"/>
	</g>
  <g>
		<path d="M31.6,51.8C31.6,51.7,31.6,51.7,31.6,51.8l0.1-0.1c0-0.1,0-0.1,0.1-0.2l0,0c0,0,0,0,0-0.1l0,0c0-0.1,0-0.1,0-0.2l0,0
			c0,0,0,0,0-0.1l0,0c0-0.1,0-0.1-0.1-0.2l0,0c0,0,0,0,0-0.1l0,0c0-0.1-0.1-0.1-0.1-0.1l0,0c0,0,0,0-0.1,0l0,0L26.7,47
			c-0.4-0.3-1-0.2-1.3,0.2c-0.3,0.4-0.2,1,0.2,1.3l0,0l2.5,1.9c-6.7-0.3-13-1.9-17.8-4.5C5,42.9,2.1,39.2,2.1,35.2
			c0-3.8,2.7-7.4,7.7-10.3c0.4-0.3,0.5-0.8,0.3-1.2c-0.2-0.4-0.8-0.5-1.2-0.3c-5.5,3.2-8.6,7.4-8.6,11.8c0,4.6,3.2,8.9,9.1,12.1
			c5.1,2.8,11.7,4.4,18.8,4.8l-2.7,2.1c-0.4,0.3-0.5,0.9-0.2,1.3c0.3,0.4,0.9,0.5,1.3,0.2l4.7-3.6l0,0c0,0,0,0,0.1,0l0,0
			C31.5,51.9,31.5,51.8,31.6,51.8L31.6,51.8z"/>
	</g>
  <g>
		<path d="M12.8,31.1l17.6,8.9l0,0l0,0c0,0,0,0,0.1,0l0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0l0,0c0,0,0.1,0,0.1,0l0,0l17.6-8.9c0.3-0.2,0.5-0.5,0.5-0.8v-3.6c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9
			c0,0,0,0,0,0v3l-15.8,8V19.6l15.8-8v11.5c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-13c0,0,0-0.1,0-0.1l0,0c0,0,0-0.1,0-0.1
			l0,0c0,0,0,0,0-0.1l0,0c0,0,0,0,0-0.1l0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2-0.2l0,0c0,0,0,0,0,0c0,0,0,0,0,0
			L31.2,0.4c-0.3-0.1-0.6-0.1-0.8,0l-2.5,1.3c-0.4,0.2-0.6,0.8-0.4,1.2s0.8,0.6,1.2,0.4l2.1-1.1l15.6,7.9l-1,0.5l-14.6,7.4
			l-14.6-7.4l-1-0.5l10.3-5.2c0.4-0.2,0.6-0.7,0.4-1.2s-0.7-0.6-1.2-0.4c0,0,0,0,0,0l-11.8,6l0,0l0,0l0,0c0,0-0.1,0.1-0.1,0.1
			c0,0,0,0,0,0l0,0c0,0,0,0,0,0.1l0,0l0,0.1l0,0c0,0,0,0,0,0.1l0,0c0,0,0,0.1,0,0.1l0,0c0,0,0,0.1,0,0.1V17c0,0.5,0.4,0.9,0.9,0.9
			c0.5,0,0.9-0.4,0.9-0.9v-5.4l15.8,8v18.2l-15.8-8v-9.2c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v9.8
			C12.4,30.7,12.5,31,12.8,31.1L12.8,31.1z"/>
	</g>
</g>
</svg>
);
}

export default RotateIcon;
