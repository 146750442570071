import React from "react";

const Spinner = function() {
  return (
    <div className={"ark-spinner"}>
      <div className={"spin-wrap"}>
        <div className={"spin-block red"}></div>
        <div className={"spin-block blue"}></div>
        <div className={"spin-block orange"}></div>
        <div className={"spin-block green"}></div>
      </div>
    </div>
  );
}

export default Spinner;
