import BABYLON from "babylonjs";
import { point3D, cube } from "./interfaces";
import closestPlane from "./closestPlane";

export default function implementClosestPlane(camera: BABYLON.ArcRotateCamera) {
    const radius: number = camera.radius, point: point3D = camera.position;
    // this cube assumes a vertical y axis, horizontal x axis, third dimensioni z axis, with positive directions being
    // left, up, toward viewer.
    const thisCube: cube = {
        f1: {
            center: {x:0, y:0, z:radius},
            name: "back"
        },
        f2: {
            center: {x:radius, y:0, z:0},
            name: "right"
        },
        f3: {
            center: {x:0, y:0, z:-radius},
            name: "front"
        },
        f4: {
            center: {x:-radius, y:0, z:0},
            name: "left"
        },
        f5: {
            center: {x:0, y:radius, z:0},
            name: "top"
        },
        f6: {
            center: {x:0, y:-radius, z:0},
            name: "bottom"
        }
    };
    return closestPlane(point, thisCube);
}
