import React from "react";
import SearchPage from "./SearchPage";
import jsonFetch from "../utilities/cubeFetch";

class SearchContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      searchTerm: null,
      searching: false,
      searchPerformed: false,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.reduceToSearchResults = this.reduceToSearchResults.bind(this);
    this.resolveMapper = this.resolveMapper.bind(this);
    this.getSearchResults = this.getSearchResults.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
    this.fetchMissingRouteContent = this.fetchMissingRouteContent.bind(this);
  }

  async handleSearch(e) {
    e.preventDefault();

    this.setState({ searching: true });

    let inputValue = e.target[0].value;
    this.props.history.push(`/search?${inputValue}`);
    let results = await this.getSearchResults(inputValue);
    this.props.setSearchCache(inputValue, results);
    this.setState({ results, searching: false, searchPerformed: true });
  }

  async getSearchResults(inputValue) {
    if (this.props.searchCache.hasOwnProperty(inputValue)) {
      return this.props.searchCache[inputValue];
    }

    let json = await jsonFetch(
      `/drupal/jsonapi/index/content?filter[fulltext]=${inputValue}`
    );

    // This represents the current Routes that contain the content from a particular node.
    let currentRoutes = json.data
      .filter((node) => node.id in this.props.activeRoute.searchIndex)
      .map((node) => this.props.activeRoute.searchIndex[node.id])
      .reduce((collector, routeArray) => {
        return collector.concat(routeArray);
      }, [])
      .map((route) => ({
        title: route.pageData.title,
        src: route.currentPath,
        id: route.uuid,
        summary: route.pageData.summary,
        breadCrumbs: route.getBreadCrumb(),
        color: route.getColor(),
      }));
    let missingNodes = json.data.filter(
      (node) => !(node.id in this.props.activeRoute.searchIndex)
    );

    let fetchedResults = await this.fetchMissingRouteContent(missingNodes);
    return currentRoutes.concat(fetchedResults);
  }

  async fetchMissingRouteContent(missing) {
    let promisedItems = missing.map((node) => {
      return new Promise(async (resolve, reject) => {
        let referAsPromising = await jsonFetch(
          `/drupal/jsonapi/node/stage?filter[field_promising_programs.id][value]=${node.id}`
        );
        let referAsEvidence = await jsonFetch(
          `/drupal/jsonapi/node/stage?filter[field_evidence_based_programs.id][value]=${node.id}`
        );
        if (referAsEvidence.status > 399 || referAsPromising.status > 399) {
          reject("There was an error fetching results");
        }
        return resolve({
          referrers: referAsPromising.data.concat(referAsEvidence.data),
          missingNode: node,
        });
      });
    });
    let allReferrers = await Promise.all(promisedItems);
    return (
      allReferrers
        // If there are no referrers, we have no where to link this
        .filter((referenceObject) => referenceObject.referrers.length > 0)
        // We need to make links for each referring Route
        .reduce((collector, referenceObject) => {
          let newObjects = referenceObject.referrers.map((referrer) => ({
            referrer: referrer,
            missingNode: referenceObject.missingNode,
          }));
          return collector.concat(newObjects);
        }, [])
        // If the referring node isn't in our searchIndex, there's no  where to link to
        .filter(
          (referenceObject) =>
            referenceObject.referrer.id in this.props.activeRoute.searchIndex
        )
        // The client's business logic says there shouldn't be a Stage node (or any type) inserted into the tree in two
        // different places, but there is nothing preventing that from happening. In the interest of being most flexible
        // let's create a link for each duplicate parent Route.
        .reduce((collector, referenceObject) => {
          let linkObjects = this.props.activeRoute.searchIndex[
            referenceObject.referrer.id
          ].map((referrer) => ({
            title: referenceObject.missingNode.attributes.title,
            id: referenceObject.missingNode.id,
            src:
              referrer.currentPath +
              "/" +
              referenceObject.missingNode.attributes.title
                .toLowerCase()
                .trim()
                .replace(/\s+/g, "-"),
            summary: referenceObject.missingNode.attributes.body.summary,
            type: referenceObject.missingNode.type.replace("node--", ""),
            breadCrumbs: referrer.getBreadCrumb(),
            color: referrer.getColor(),
          }));

          return collector.concat(linkObjects);
        }, [])
    );
  }

  inputHandler(e) {
    this.setState({ searchTerm: e.target.value });
  }

  componentDidMount() {
    let searchValue = this.props.history.location.search;
    if (searchValue) {
      // Since we're coming back to this page, we could have cached this result.
      this.getSearchResults(searchValue.substring(1)).then((results) => {
        this.setState({
          searchTerm: searchValue.substring(1),
          results,
        });
      });
    }
  }

  resolveMapper(promiseArray) {
    return Promise.all(promiseArray);
  }

  reduceToSearchResults(acc, node) {
    let links = this.props.activeRoute.findByUUID(node.id).map((route) => {
      let clonedNode = JSON.parse(JSON.stringify(node));
      clonedNode.path = route.currentPath;
      return clonedNode;
    });
    // if there are existing Routes, push them into the list.
    if (links.length > 0) {
      acc.push(links);
    }
    // otherwise we need to fetch the node(s)
    else {
      acc.push(node);
    }

    return acc;
  }

  async fetchReverseReference(uuid) {
    let referAsPromising = await jsonFetch(
      `/drupal/jsonapi/node/stage?filter[field_promising_programs.id][value]=${uuid}`
    );
    let referAsEvidence = await jsonFetch(
      `/drupal/jsonapi/node/stage?filter[field_evidence_based_programs.id][value]=${uuid}`
    );

    return referAsPromising.data.concat(referAsEvidence.data);
  }

  render() {
    return (
      <SearchPage
        handleSearch={this.handleSearch}
        inputHandler={this.inputHandler}
        {...this.state}
      />
    );
  }
}

export default SearchContainer;
