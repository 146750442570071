import ErrorBoundary from "./ErrorBoundary";
import CSSTransition from "react-transition-group/cjs/CSSTransition";
import CubeScene from "./CubeScene";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";
import ContentPanel from "./ContentPanel";
import Toggle from "./Toggle";
import IconChevron from "../assets/IconChevron";
import IconHamburger from "../assets/IconHamburger";
import IconInfo from "../assets/IconInfo";
import IconCross from "../assets/IconCross";
import React, { useState } from 'react';
import MenuContainer from "./MenuContainer";
import IconSearch from "../assets/IconSearch";
import wrapWithCloser from "./withCloser";
import RotateIcon from "../assets/RotateIcon"

function Page(props) {
  const [toggleVar, setToggle] = useState("panel-open");
  const [toggleText, setToggleText] = useState("Collapse Content")
  const [flyoutVar, setFlyout] = useState("flyout-close");
  const [showCubeGuidence, setCubeGuidence] = useState(true)

  const contentHandler = function() {

    if (toggleVar === "panel-open") {
      setToggle("panel-close");
      setToggleText("Open Content")
    }
    else {
      setToggle("panel-open");
      setToggleText("Collapse Content")
    }
  }

  const flyoutHandler = () => {
    if (flyoutVar === "flyout-close") {
      setFlyout("flyout-open");
    }
    else {
      setFlyout("flyout-close");
      // if the flyout is open, and we're following a link, we also want the content to open.
      if (toggleVar === "panel-close") {
        setToggle("panel-open");
        setToggleText("Collapse Content")
      }
    }
  }

  let year = new Date().getFullYear();

  let showCube = props.sections.length > 0;

  // This Higher Order function adds the flyoutHandler onClick to the component passed to it.
  const closer = wrapWithCloser(flyoutHandler);

  const guidance = showCubeGuidence ? <div className={"interact-indicator"}>
    Click the cube to access Stage & Program content<br /><RotateIcon width={48} height={48} />
  </div> : '';

  const location = props.location;

  return (
    <main className="App">
      <section className={"menu " + flyoutVar}>
        <div className={"menu-top mb-4"}>
          <Toggle handler={flyoutHandler} class={"link flyout-close p-0"} iconBefore={<IconCross className={"icon cross mr-3"} height={'45px'} width={'45px'} fill={'#fff'} />} />
          {closer(<Link className={"fake-search"} to={'/search'}>
            Search
                        <IconSearch
              height={"20px"}
              width={"20px"}
              className={"icon search ml-3"}
              fill={"#141826"} />
          </Link>)}
        </div>
        <MenuContainer activeRoute={props.activeRoute} flyoutVar={flyoutVar} closer={closer} />
        <a className={"btn btn-info mb-4"} href={'https://www.cvent.com/d/m7q133'} target={"_blank"}>Request Training</a>
        <p className={"small"}>Copyright {year} National Association Of Drug Court Professionals. All Rights Reserved.</p>
        <p className={"small"} style={{ fontSize: "14px" }}>Disclaimer: This project was supported by Grant NoG18990NDCP02A awarded by the Office of National Drug Control Policy (ONDCP) of the Executive Office of the President. Points of views or opinions in this document are those of the author and do not necessarily represent the official position nof the Executive Office of the President.</p>
        {closer(<Link className={"small"} to={'/about-the-ark/privacy-policy/'}><u>Privacy Policy</u></Link>)}
        <br />
        {closer(<Link className={"small"} to={'/about-the-ark/terms-&-conditions/'}><u>Terms & Conditions</u></Link>)}
      </section>
      <section className={"section cube__section " + toggleVar}>
        {guidance}
        <Toggle handler={flyoutHandler} title={"Menu"} class={"link flyout-open"} iconBefore={<IconHamburger className={"icon hamburger mr-3"} height={"32px"} width={"65px"} fill={"#141826"} />} />
        <Toggle handler={contentHandler} title={"Open Content"} class={"link menu-open"} iconAfter={<IconChevron height={"30px"} width={"40px"} className={"icon chevron ml-2"} fill={"#141826"} />} />
        <ErrorBoundary>
          <div className={"cube-wrapper"}>
            <CSSTransition timeout={1000} in={showCube} classNames={"cube"} unmountOnExit>
              <CubeScene
                initializeSections={props.sections}
                setPage={props.setPageFromFace}
                className={"canvas-wrapper"}
                registerScene={props.registerScene}
                setGuidance={setCubeGuidence}
              />
            </CSSTransition>
            <CSSTransition timeout={500} in={!showCube} classNames={"spinner"}>
              <div className={"spinner-layout"}>
                <Spinner />
              </div>
            </CSSTransition>
          </div>
        </ErrorBoundary>
        <div className={"link-bar "}>
          <Link className={"btn btn-controls hide-submit-for-print"} to={{
            pathname: "/high-risk",
            state: { spinCube: true }
          }}><IconChevron height={"20px"} width={"20px"} className={"icon chevron mr-2 left"} fill={"#141826"} /> High Risk (Left)</Link>
          <Link className={"btn btn-controls btn-no-icon hide-submit-for-print"} to={{
            pathname: "/",
            state: { spinCube: true }
          }}>Home</Link>
          <Link className={"btn btn-controls hide-submit-for-print"} to={{
            pathname: "/low-risk",
            state: { spinCube: true }
          }}>Low Risk (Right) <IconChevron height={"20px"} width={"20px"} className={"icon chevron ml-2 right"} fill={"#141826"} /></Link>
        </div>
      </section>
      <section className={"section panel__section " + toggleVar}>
        <div className={"print-img-container"}>
          <img className={"logo-nadcp"} src="/drupal/themes/custom/nadcp/NADCP_300.png" />
          <img className={"logo-cube"} src="/drupal/themes/custom/nadcp/nadcp-ark-400x400.png" />
        </div>
        <div className={"top-panel"}>
          <Toggle handler={contentHandler} title={toggleText} class={"link link-collapse"} iconBefore={<IconChevron height={"20px"} width={"20px"} className={"icon chevron chevron-dark"} fill={"#fff"} />} />
          <Link className={"fake-search"} to={'/search'}>
            Search
                <IconSearch
              height={"20px"}
              width={"20px"}
              className={"icon search ml-3"}
              fill={"#141826"} />
          </Link>
          <Link className={"link ark-info"} to={"/about-the-ark/how-to-use-the-ark/"}><IconInfo height={"25px"} width={"25px"} className={"icon info mr-2"} fill={"#1e558d"} />How To Use The ARK</Link>
        </div>
        <ContentPanel activeRoute={props.activeRoute} history={props.history} setSearchCache={props.setSearchCache} searchCache={props.searchCache} location={props.location} />
      </section>
    </main>
  );
}

export default Page;
