// This function does not return a reference but a branch new Object
function extractNodeFromTreePath(path, routes, state) {
  if(path.length === 0) return state;

  let nextRoute;
  for (let key in routes) {
    if (key === path[0]) {
      nextRoute = routes[key]
      // Page data is overridden until the final page.
      state.pageData = nextRoute.pageData
      // Cube data is merged
      state.cubeState = {...state.cubeState, ...nextRoute.cubeState};
      state.currentPath = nextRoute.currentPath;
      return extractNodeFromTreePath(path.slice(1), nextRoute.subRoutes, state);
    }
  }

  return state;

}

export default extractNodeFromTreePath;

